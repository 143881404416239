import { useEffect, useState } from "react";
import styles from "./CancelMembership.module.scss";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import Navigation from "../../components/Navigation/Navigation";
import { ReactComponent as CircleArrowIcon } from "../../assets/icons/circle-arrow.svg";
import { ReasonStep } from "./CancelSteps/ReasonStep";
import { FeedbackStep } from "./CancelSteps/FeedbackStep";
import { useHistory, Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";
import { format } from "date-fns";
import Loader from "../../components/Loader/Loader";
import { DowngradeStep } from "./CancelSteps/DowngradeStep";
import { IPatient } from "../../types/patient";
import amplitude from "amplitude-js";

interface IProps {
  uid: string;
}

export interface IFormsData {
  reason?: string;
  reasonValue?: number;
  feedback?: string;
  wantsMessagingOnly?: boolean;
}

export default function CancelMembership({ uid }: IProps) {
  const [step, setStep] = useState(1);
  const [formsData, setFormsData] = useState<IFormsData | null>(null);
  const [chooseAffordableOption, setChooseAffordableOption] = useState(false);
  const [typeAffordable, setTypeAffordable] = useState("");
  const [patientData, setPatientData] = useState<IPatient | null>(null);
  const [loading, setLoading] = useState(false);
  const [cancelDay, setCancelDay] = useState("");

  const history = useHistory();

  const firestore = firebase.firestore;

  const functions = new FirebaseApi().firebaseFunctions();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const fetchPatientData = () => {
    firestore()
      .collection("patients")
      .doc(uid)
      .onSnapshot(async (documentSnapshot) => {
        const user: any = documentSnapshot.data();
        setPatientData(user);
      });
  };

  const handleSubmit = async (wantsALaCarte: boolean) => {
    try {
      const data = {
        patientID: uid,
        reason: formsData?.reason || "",
        reasonValue: formsData?.reasonValue || 0,
        feedback: formsData?.feedback,
        wantsALaCarte,
        wantsMessagingOnly: !!formsData?.wantsMessagingOnly,
      };

      setLoading(true);
      const response = await functions.cancelSubscription(data);
      if (response.success) {
        setLoading(false);
        setStep(4);
        const date = format(new Date(response.cancelAt * 1000), "MM/dd/yyyy");
        setCancelDay(date);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const backButton = () => {
    if (step === 1) {
      history.push({
        pathname: "/user",
        state: { activeTab: 3 },
      });
    } else if (step === 3) {
      const eligibleForDowngradeStep =
        !patientData?.eligibleForMaintenanceMode && !patientData?.eligibleForALaCarte;
      if (eligibleForDowngradeStep) {
        setStep((prev) => prev - 2);
      } else {
        setStep((prev) => prev - 1);
      }
    } else {
      setStep((prev) => prev - 1);
    }
  };

  const backToHome = () => {
    history.push("/home");
  };

  const headingContent = () => {
    switch (step) {
      case 1:
        return "We’re sorry to see you go! Please help us improve our service by telling us what we could have done better";
      case 2:
        return "Would you be interested in a more affordable version of Neura?";
      case 3:
        return "Any other feedback you’d like to provide? (Optional)";
      default:
        return "";
    }
  };

  const handleMoveToNextStep = () => {
    const eligibleForDowngradeStep =
      !patientData?.eligibleForMaintenanceMode && !patientData?.eligibleForALaCarte;

    if (!eligibleForDowngradeStep) setStep((prev) => prev + 1);
    else setStep(3);
  };

  const ContentStep = () => {
    switch (step) {
      case 1:
        return (
          <ReasonStep
            setStep={handleMoveToNextStep}
            selectReason={formsData?.reasonValue}
            select={(reasonValue, reason) => setFormsData({ ...formsData, reasonValue, reason })}
          />
        );
      case 2:
        return (
          <DowngradeStep
            setStep={setStep}
            setChooseAffordableOption={setChooseAffordableOption}
            setTypeAffordable={setTypeAffordable}
            handleChange={(value) => setFormsData({ ...formsData, wantsMessagingOnly: value })}
            handleSubmit={handleSubmit}
            eligibleForMaintenanceMode={patientData?.eligibleForMaintenanceMode}
            eligibleForALaCarte={patientData?.eligibleForALaCarte}
          />
        );
      case 3:
        return (
          <FeedbackStep
            feedbackValue={formsData?.feedback}
            setFeedback={(feedback) => setFormsData({ ...formsData, feedback })}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => {
    if (patientData) {
      const eligibleForMaintenanceMode = patientData?.eligibleForMaintenanceMode;
      const eligibleForALaCarte = patientData?.eligibleForALaCarte;

      if (!eligibleForALaCarte && !eligibleForMaintenanceMode) {
        amplitude.getInstance().logEvent("cancellation_offered_no_choice");
      }
    }
  }, [patientData]);

  return (
    <div>
      {!isResponsiveView && (
        <div className={styles.root}>
          <div className={styles.container}>
            <Navigation patientData={patientData} />
          </div>
        </div>
      )}
      {isResponsiveView && (
        <div className={styles.mobile}>
          <div className={styles.mobileTitle}>
            {step < 4 && <CircleArrowIcon className={styles.backArrow} onClick={backButton} />}
          </div>
          <Link to="/home" className={styles.mobileButton}>
            <Cancel />
          </Link>
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.contentTabs}>
              <div className={styles.contentHeader}>
                {step < 4 && !isResponsiveView && !chooseAffordableOption && (
                  <CircleArrowIcon className={styles.backArrow} onClick={backButton} />
                )}
                {step < 4 ? (
                  <>
                    {!chooseAffordableOption && (
                      <h3 className={styles.heading}>
                        {headingContent()}
                        {step === 2 && (
                          <p className={styles.subheading}>
                            Instead of losing complete access to Neura, consider these options:
                          </p>
                        )}
                      </h3>
                    )}
                  </>
                ) : (
                  <>
                    {!chooseAffordableOption && (
                      <div>
                        <h3 className={`${styles.heading} ${styles.headingCenter}`}>
                          Your Neura Health membership has been canceled. You will continue to have
                          access to your account until the end of your billing cycle, on {cancelDay}
                          .
                        </h3>
                        <button className={styles.contentFormButton} onClick={backToHome}>
                          Back to Home
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>

              {chooseAffordableOption && (
                <>
                  <h3 className={styles.heading} style={{ textAlign: "center" }}>
                    {typeAffordable === "visit-only" &&
                      `Your concierge will reach out to you shortly to set you up on your visit-only membership.`}
                    {typeAffordable === "maintenance-plan" && (
                      <>
                        <p>
                          Thank you! Your care team will review this request and reach out to you
                          with more information regarding your eligibility for this program.
                        </p>
                        <p style={{ margin: "20px 0" }}>
                          Kindly note, this service is only available to patients who have been
                          clinically approved for text-based medication management. If you have any
                          additional questions, please reach out to your care team.
                        </p>
                        <p>
                          Please note that we may still require you to do an annual check in with a
                          provider. That appointment is subject to our standard visit fees or copay.
                        </p>
                      </>
                    )}
                  </h3>

                  <button className={styles.contentFormButton} onClick={backToHome}>
                    Back to Home
                  </button>
                </>
              )}

              <div className={styles.contentWrap}>{ContentStep()}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
