const bottomNavMenu: LinkT[] = [
  {
    title: "Privacy Policy",
    to: "/privacy-policy",
  },
  {
    title: "Terms and Conditions",
    to: "/terms-and-conditions",
  },
  {
    title: "Notice of HIPAA Privacy Practices",
    to: "/hipaa-privacy-practices",
  },
  {
    title: "Consent for Telemedicine",
    to: "/consent-for-telemedicine",
  },
];

type LinkT = {
  title: string;
  to: string;
};

export default bottomNavMenu;
