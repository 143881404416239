import React from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import cn from "classnames";
import * as Yup from "yup";
import OnboardingFormError from "../OnboardingForm/OnboardingFormComponents/OnboardingFormError/OnboardingFormError";
import getDateForHubspot from "../../helpers/getDateForHubspot";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import PaperPlane from "../../assets/icons/paper-plane.svg";
import EmailIcon from "../../assets/icons/email-action-unread.svg";

import styles from "./NewsletterForm.module.scss";

type FormValuesT = {
  email: string;
};

const NewsletterForm = () => {
  const onSubmit = async (values: FormValuesT, formikHelpers: FormikHelpers<FormValuesT>) => {
    formikHelpers.setFieldError("email", "Thank you for subscribing!");

    try {
      const functions = new FirebaseApi().firebaseFunctions();
      await functions.createHubspotContact(values.email);

      await functions.updateHubspotContact("footer_signup", {
        email: values.email,
        eventValue: getDateForHubspot(),
      });
    } catch (error) {
      console.log(error);
    }

    formikHelpers.setFieldError("email", "Thank you for subscribing!");
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Please enter a valid email.").required("Email is required."),
      })}
    >
      {({ touched, errors }) => {
        return (
          <Form>
            <div className={cn(styles.inputContainer)}>
              <div className={styles.container}>
                <img className={styles.mailIcon} src={EmailIcon} alt="" />

                <Field name="email" className={styles.input} placeholder="Your email" />
              </div>
              <button type="submit" className={styles.sendImgContainer}>
                <img className={styles.inputSendImg} src={PaperPlane} alt="" />
              </button>
            </div>
            {touched["email"] && errors["email"] && (
              <OnboardingFormError className={styles.error} errors={errors} inputName={"email"} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default NewsletterForm;
