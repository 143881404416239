import React, { useCallback, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import cn from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import { ReactComponent as IconSpin } from "../../../assets/icons/spinner.svg";
import DoctorTelehealthImage from "../../../assets/images/Doctor-Telehealth.png";
import styles from "./OnboardingFormSectionPayment.module.scss";
import { config } from "../../../config";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as BackgroundIcon } from "../../../assets/images/svg/onboarding-phone-background-icon.svg";

const stripeInstance = loadStripe(config.stripeApiKey);

const StripeHoc = (props: any) => {
  const { Component, uid, formData, userEmail, patientData = {}, rewardfulRef, coupon } = props;
  const [stripeData, setStripeData] = useState();
  const [loadingPhrase, setLoadingPhrase] = useState<string>("Setting up your account...");
  const [stripe, setStripe] = useState<Stripe>();

  const getStripe = useCallback(
    async function () {
      try {
        setLoadingPhrase("We look forward to helping you find relief!");
        const functions = new FirebaseApi().firebaseFunctions();

        const { data } = await functions.createCustomerAndSubscribe({
          patientID: uid || formData.uid || patientData.uid,
          email: patientData?.email || userEmail || formData?.email,
          isTest: !(process.env.REACT_APP_ENV === "production"),
          referral: rewardfulRef,
          couponID: coupon?.id,
        });

        if (data?.paymentIntentSecret && !data.error) {
          setStripeData(data);

          const stripeFulfilled = await stripeInstance;

          if (stripeFulfilled) {
            setStripe(stripeFulfilled);
          }
        } else {
          setLoadingPhrase("Setting up your account...");
          throw new Error(data.error);
        }
      } catch (error) {}
    },
    [uid, userEmail, formData?.uid, formData?.email, coupon?.id]
  );

  useEffect(() => {
    if ((patientData || formData) && !stripe) {
      getStripe();
    }
    /* eslint react-hooks/exhaustive-deps: "off"*/
  }, [stripe]);

  return stripe ? (
    //@ts-ignore
    <Elements stripe={stripe}>
      <Component
        {...props}
        stripe={stripe}
        stripePatientData={stripeData}
        patientData={patientData}
      />
    </Elements>
  ) : (
    <div className={cn(styles.wrapper, "d-flex flex-column h-100")}>
      <BackgroundIcon className={styles.backgroundIcon} />

      <BackgroundIcon className={styles.backgroundIcon2} />

      <LazyLoadImage alt="doctor" effect="blur" src={DoctorTelehealthImage} />

      <OnboardingFormHeading title={loadingPhrase} />

      <div className={styles.loader}>
        <IconSpin />
      </div>
    </div>
  );
};

export default StripeHoc;
