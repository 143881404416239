import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Container from "../Container/Container";
import StartTodayButton from "../StartTodayButton/StartTodayButton";
import CrossIcon from "../../assets/icons/cross.svg";
import Leaves from "../../assets/images/burger-menu-leaves.png";
import { getNavMenu } from "../../helpers/getNavMenu";

import styles from "./BurgerMenu.module.scss";
import AppStoreButton from "../AppStoreButton/AppStoreButton";

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  startButtonClick: () => void;
}

const BurgerMenu = ({ onClose, isOpen, startButtonClick }: IProps) => {
  const renderRoutes = getNavMenu().map((el, idx) => (
    <a
      key={el.title}
      href={`https://neurahealth.co${el.to}/${window.location.search}`}
      className={cn(styles.link, {
        [styles.activeLink]: window.location.pathname === el.to,
      })}
      onClick={onClose}
    >
      {el.title}
    </a>
  ));

  return (
    <Container
      className={cn(styles.burgerMenuContainer, {
        [styles.open]: isOpen,
      })}
    >
      <div className={styles.buttonsContainer}>
        <img src={CrossIcon} alt="" onClick={onClose} />

        <Link to="/login" className={cn(styles.link, styles.login)}>
          Login
        </Link>

        <StartTodayButton menuOpened={isOpen} text="Start Today" onClick={startButtonClick} />
      </div>

      <img className={styles.leavesImage} src={Leaves} alt="" />

      <div className={styles.navigationMenu}>{renderRoutes}</div>

      <AppStoreButton className={styles.downloadAppStoreButton} />
    </Container>
  );
};

export default BurgerMenu;
