import React from "react";
import cn from "classnames";
import styles from "./OnboardingFormError.module.scss";
import { ReactComponent as IconAlert } from "../../../../assets/icons/alert.svg";

interface IOnboardingFormError {
  className?: string;
  inputName: string;
  errors: any;
}

const OnboardingFormError = (props: IOnboardingFormError) => {
  const { className, inputName, errors } = props;
  return (
    <p className={cn(styles.element, "d-inline-flex align-items-center", className)}>
      <span className={styles.iconContainer}>
        {errors[inputName] === "Thank you for subscribing!" || (
          <IconAlert className={styles.icon} />
        )}
      </span>

      {errors[inputName]}
    </p>
  );
};
export default OnboardingFormError;
