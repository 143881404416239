import { useEffect, useState } from "react";
import styles from "../CancelMembership.module.scss";

interface IProps {
  setStep: () => void;
  selectReason?: number;
  select: (id: number, value: string) => void;
}

const reasons = [
  {
    id: 1,
    value: "Neura helped me find relief",
  },
  {
    id: 2,
    value: "I need a doctor who can write prescriptions in my state",
  },
  {
    id: 3,
    value: "I don't want to pay a membership fee",
  },
  {
    id: 4,
    value: "I have had a poor experience with Neura",
  },
  {
    id: 5,
    value: "Neura doesn't offer care for my specific condition yet",
  },
  {
    id: 6,
    value: "Visits not covered by my health insurance",
  },
  {
    id: 7,
    value: "I prefer just texting with my provider rather than doing full appointments",
  },
  {
    id: 8,
    value: "The fee for medical visits is too expensive",
  },
  {
    id: 10,
    value: "I needed an appointment sooner than what was available",
  },
  {
    id: 11,
    value: "I was not able to schedule a visit with my preferred clinician",
  },
];

// {
//   id: 9,
//   value: "Other",
// },

interface IShuffledReason {
  value: string;
  id: number;
}

export const ReasonStep = ({ setStep, selectReason, select }: IProps) => {
  const [shuffledReason, setShuffledReason] = useState<IShuffledReason[]>([]);
  const selectOption = (id: number, value: string) => {
    setStep();
    select(id, value);
  };

  useEffect(() => {
    const shuffled = reasons
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    setShuffledReason(shuffled);
  }, []);

  return (
    <div className={styles.form}>
      {shuffledReason.map((item) => (
        <div
          key={item.id}
          onClick={() => selectOption(item.id, item.value)}
          className={`${styles.formItem} ${item.id === selectReason && styles.formItemSelected}`}
        >
          {item.value}
        </div>
      ))}
      <div
        onClick={() => selectOption(9, "Other")}
        className={`${styles.formItem} ${9 === selectReason && styles.formItemSelected}`}
      >
        Other
      </div>
    </div>
  );
};
