import styles from "./ResourceContentModal.module.scss";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/cancel.svg";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

interface IProps {
  title: string;
  image: string;
  description: any;
  authorName: string;
  options: any;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ResourceContentModal = ({
  title,
  description,
  authorName,
  options,
  handleClose,
}: IProps) => {
  return (
    <>
      <div className={styles.background} onClick={handleClose}></div>
      <div className={styles.wrapper}>
        <div className={styles.wrapperCloseButton}>
          <div className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.modalRoot}>
          <div className={styles.root}>
            <h3 className={styles.heading}>{title}</h3>
            <p className={styles.authorName}>{authorName}</p>
            <div className={styles.content}>
              {
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(description, options),
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
