import { useEffect, useState } from "react";
import Navigation from "../../components/Navigation/Navigation";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { ReactComponent as Wand } from "../../assets/images/svg/wand.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";

import styles from "./Profile.module.scss";
import firebase from "firebase/compat/app";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import { LoaderHorizontal } from "../../components/LoaderHorizontal/LoaderHorizontal";
import { useInput } from "../../hooks/useInput";
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { Link, useHistory } from "react-router-dom";
import { MyForms } from "./Tabs/MyForms";
import Skeleton from "react-loading-skeleton";
import amplitude from "amplitude-js";

type UpdatePaymentType = "UpdatePaymentMethod" | "UpdateMembershipPlan";

export interface IUserForm {
  title: string;
  list: {
    completed: boolean;
    required: boolean;
    title: string;
    url: string;
  }[];
}

const tabs = [
  { title: "Basic info", id: 1 },
  { title: "My forms", id: 2 },
  { title: "Payment Settings", id: 3, hideForEnterpriseUser: true },
];

type ProfileProps = {
  uid: string;
  tab?: number;
};

export default function Profile({ uid, tab }: ProfileProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation<any>();
  const [activeTab, setActiveTab] = useState(tab || state?.activeTab || 1);
  const [patientData, setPatientData] = useState<any>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [updatePaymentLoading, setUpdatePaymentLoading] = useState(false);
  const [activePaymentLoader, setActivePaymentLoader] = useState<UpdatePaymentType | null>(null);
  const [userFormList, setUserFormList] = useState<IUserForm[]>([]);
  const [isFeachingFormList, setIsFeachingFormList] = useState<boolean>(true);

  const name = useInput("");
  const email = useInput("");
  const lastName = useInput("");
  const dateOfBirth = useInput("");
  const phoneNumber = useInput("");

  const history = useHistory();
  let firestore = firebase.firestore;

  const user: any = firebase.auth().currentUser;
  const functions = new FirebaseApi().firebaseFunctions();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const hasChanged: boolean =
    patientData &&
    (name.value !== patientData.firstName ||
      email.value !== patientData.email ||
      lastName.value !== patientData.lastName ||
      dateOfBirth.value !== patientData.birthDate ||
      phoneNumber.value !== patientData.phoneNumber);

  const saveBasicInfo = async () => {
    const data = {
      firstName: name.value,
      email: email.value,
      lastName: lastName.value,
      birthDate: dateOfBirth.value,
      phoneNumber: phoneNumber.value,
    };

    firestore()
      .collection("patients")
      .doc(uid)
      .update(data)
      .then(() => {
        if (user && patientData.email !== email.value) {
          user
            ?.updateEmail(email.value)
            .then(() => {
              setIsSaved(true);
              setTimeout(() => {
                setIsSaved(false);
                window.location.reload();
              }, 2000);
            })
            .catch((error: any) => {
              alert("Something went wrong, please try again.");
            });
        } else {
          setIsSaved(true);
          setTimeout(() => {
            setIsSaved(false);
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        alert("Something went wrong, please try again.");
      });
  };

  const openStripePortal = function stripePortal() {
    setUpdatePaymentLoading(true);
    functions
      .createCustomerPortalSession({
        // @ts-ignore
        patientID: uid,
        isTest: !(process.env.REACT_APP_ENV === "production"),
      })
      .then((res) => {
        setUpdatePaymentLoading(false);
        window.location.href = res;
      })
      .catch((err) => {
        setUpdatePaymentLoading(false);
      });
  };

  const redirectToCancel = () => {
    history.push("/cancelMembership");
    amplitude.getInstance().logEvent("cancellation_initiated_by_user");
  };

  useEffect(() => {
    setIsFeachingFormList(true);
    functions
      .getPatientFormList({ patientID: uid })
      .then((res) => {
        setUserFormList(res.data);
      })
      .catch((err) => {
        setUserFormList([]);
      })
      .finally(() => {
        setIsFeachingFormList(false);
      });
  }, [uid]);

  useEffect(() => {
    if (state?.activeTab) setActiveTab(state.activeTab);
  }, [state?.activeTab]);

  const renderActiveTabContent = (activeTabID: number) => {
    const basicInfo = 1;
    const myForms = 2;
    const paymentSettings = 3;
    switch (activeTabID) {
      case basicInfo:
        return (
          <div className={styles.contentForm}>
            <div className={styles.contentFormBlock}>
              <Input label="First Name" field={name} onChange={name.onChange} />
              <Input label="Last Name" field={lastName} onChange={lastName.onChange} />
              <Input label="Date of birth" field={dateOfBirth} onChange={dateOfBirth.onChange} />
              <Input label="Email" field={email} onChange={email.onChange} />
              <Input label="Phone number" field={phoneNumber} onChange={phoneNumber.onChange} />
            </div>
            <button
              disabled={!hasChanged}
              onClick={saveBasicInfo}
              className={styles.contentFormButton}
            >
              Save
            </button>
          </div>
        );
      case myForms:
        if (isFeachingFormList) {
          return (
            <Skeleton count={5} height={90} borderRadius={20} style={{ marginBottom: "20px" }} />
          );
        }
        return userFormList.map((item) => <MyForms {...item} />);
      case paymentSettings:
        return (
          <div className={styles.form}>
            <div
              onClick={() => {
                openStripePortal();
                setActivePaymentLoader("UpdatePaymentMethod");
              }}
              className={styles.formItem}
            >
              Update Payment Method
              {updatePaymentLoading && activePaymentLoader === "UpdatePaymentMethod" ? (
                <LoaderHorizontal />
              ) : (
                <Arrow className={styles.arrow} />
              )}
            </div>
            <div
              onClick={() => {
                openStripePortal();
                setActivePaymentLoader("UpdateMembershipPlan");
              }}
              className={styles.formItem}
            >
              Update Membership Plan
              {updatePaymentLoading && activePaymentLoader === "UpdateMembershipPlan" ? (
                <LoaderHorizontal />
              ) : (
                <Arrow className={styles.arrow} />
              )}
            </div>
            <div onClick={redirectToCancel} className={`${styles.formItem} ${styles.formItemRed}`}>
              Cancel Membership
              <Arrow className={styles.arrow} />
            </div>
          </div>
        );
    }
  };

  useEffect((): any => {
    let subscription: any;
    if (uid) {
      setLoading(true);

      subscription = firestore()
        .collection("patients")
        .doc(uid)
        .onSnapshot(async (documentSnapshot) => {
          const user: any = documentSnapshot.data();
          setPatientData(user);
          name.setValue(user.firstName);
          email.setValue(user.email);
          lastName.setValue(user.lastName);
          dateOfBirth.setValue(user.birthDate);
          phoneNumber.setValue(user.phoneNumber);
          setLoading(false);
        });
    }

    return () => subscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, uid]);

  useEffect(() => {
    document.body.classList.toggle("no-chat", true);
  }, [isResponsiveView]);

  useEffect(() => {
    if (patientData && patientData.kustomerID) {
      // @ts-ignore
      Kustomer.start({ brandId: "61b91090a1a78441a08b05a8" }, function () {
        const functions = new FirebaseApi().firebaseFunctions();
        functions.getJWTToken(uid).then(async (jwtToken) => {
          // @ts-ignore
          await Kustomer.login({ jwtToken: jwtToken });
        });
      });
    }
  }, [patientData, uid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  if (loading) return <Loader />;

  return (
    <div className={styles.profile}>
      {isSaved && <div className={styles.profileSuccess}>Successfully saved!</div>}
      <div className={styles.container}>
        {!isResponsiveView && <Navigation patientData={patientData} />}
        {isResponsiveView && activeTab && (
          <div className={styles.mobile}>
            <div className={styles.mobileTitle}>{tabs[activeTab - 1]?.title}</div>
            <Link to="/home" className={styles.mobileButton}>
              <Cancel />
            </Link>
          </div>
        )}

        <div className={styles.header}>
          <div className={styles.headerImage}>
            <div className={styles.headerAva}>
              <div className={styles.headerAvaImage}>{patientData?.firstName.slice(0, 1)}</div>
              <Wand className={styles.headerIcon} />
            </div>
            <div className={styles.headerBlock}>
              <div className={styles.headerName}>
                {patientData?.firstName} {""}
                {patientData?.lastName}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <ul className={styles.tabsTop}>
            {tabs
              .filter((item) => !patientData?.partnerName || !item.hideForEnterpriseUser)
              .map((tab) => (
                <li
                  key={tab.id}
                  className={classNames(styles.tab, activeTab === tab.id && styles.active)}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.title}
                </li>
              ))}
            <div className={styles.tabsTopBorder} />
          </ul>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentTabs}>
          <div className={styles.contentWrap}>{renderActiveTabContent(activeTab)}</div>
        </div>
      </div>
    </div>
  );
}
