import OnboardingFormHeading from "../OnboardingFormHeading/OnboardingFormHeading";
import { PersonalDataForm } from "../PersonalDataForm/PersonalDataForm";
import styles from "./ActivateMemberFailureModal.module.scss";
import { IOnboardingFormSection } from "../../OnboardingFormSection.interface";
import { ReactComponent as BackgroundIcon } from "../../../../assets/images/svg/onboarding-form-activate-member-failur.svg";

interface IProps extends IOnboardingFormSection {
  loading: boolean;
  error?: string;
}

export const ActivateMemberFailureModal = (props: IProps) => {
  return (
    <div className={styles.Root}>
      <OnboardingFormHeading
        className={styles.header}
        title="We could not verify your eligibility"
      />
      <p className={styles.subheader}>
        Please make sure your details are correct and match your insurance card / company ID.
      </p>

      <PersonalDataForm withActivateMember {...props}>
        <BackgroundIcon className={styles.BackgroundIcon} />
      </PersonalDataForm>
    </div>
  );
};
