import React from "react";
import styles from "./ReferComponent.module.scss";
import docTelehealth from "../../assets/images/Doctor-Telehealth.png";

interface IReferComponentProps {
  referralLink: any;
}

export default function ReferComponent(props: IReferComponentProps) {
  const { referralLink } = props;

  return (
    <div className={styles.container}>
      <img className={styles.mainImage} src={docTelehealth} alt="doctor-telehealth" />
      <div className={styles.text}>
        Here is your unique link to send to your friends. Click the link to copy it.
      </div>
      <div className={styles.textReferralLink}>
        <button onClick={() => navigator.clipboard.writeText(referralLink)}>{referralLink}</button>
      </div>
      <div className={styles.text}>
        You will receive a $30 credit toward Neura membership and your friend will receive a $15
        credit.
      </div>
      <div className={styles.text}>
        For you to earn your $30 credit, your friend must remain a monthly Neura member for at least
        2 months, or start a quarterly or annual membership.
      </div>
    </div>
  );
}
