// Keep in sync with server side
export const CarePlanItemCompletionStatus = {
  INCOMPLETE: 0,
  COMPLETE: 1,
  NOT_APPLICABLE: 2,
} as const;

// Keep in sync with server side
export const CarePlanItemSubType = {
  CONTENT_MEDICATION: 1,
  CARE_TEAM: 2,
  BOOK_APPOINTMENT: 3,
  COACHING_CHECKIN: 4,
  CONTENT_EXERCISE: 5,
  BOOK_COACHING: 6,
  CONTENT_GENERIC: 7,
  TRACK_HEADACHES: 8,
  FOOD: 9,
  GENERIC_URL: 10,
  // Add more types here
} as const;

export const reverseItemSubType = {
  1: "CONTENT_MEDICATION",
  2: "CARE_TEAM",
  3: "BOOK_APPOINTMENT",
  4: "COACHING_CHECKIN",
  5: "CONTENT_EXERCISE",
  6: "BOOK_COACHING",
  7: "CONTENT_GENERIC",
  8: "TRACK_HEADACHES",
  9: "FOOD",
  10: "GENERIC_URL",
} as const;
