import React, { ReactFragment } from "react";
import styles from "./Grid.module.scss";
import cn from "classnames";

interface IGrid {
  children: React.ReactElement<IGridElem> | React.ReactElement<IGridElem>[] | ReactFragment;
  gap?: number;
  rowGap?: number;
  cols?: 10;
  className?: string;
}

interface IGridElem {
  children: any;
  size?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xs?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fhd?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
}

const Grid = (props: IGrid) => {
  const { gap, cols, rowGap, className } = props;

  return (
    <div
      className={cn(styles.area, cols && styles[`hasCols-${cols}`], className)}
      style={{ gridColumnGap: `${gap}rem`, gridRowGap: `${rowGap}rem` }}
    >
      {props.children}
    </div>
  );
};
export default Grid;

export const GridElem = (props: IGridElem) => {
  const { children, size, xs, md, lg, xl, fhd, className } = props;
  return (
    <div
      className={cn(
        styles.element,
        size && styles[`hasSize-${size}`],
        xs && styles[`hasSize-xs-${xs}`],
        md && styles[`hasSize-md-${md}`],
        lg && styles[`hasSize-lg-${lg}`],
        xl && styles[`hasSize-xl-${xl}`],
        fhd && styles[`hasSize-fhd-${fhd}`],
        className && className
      )}
    >
      {children}
    </div>
  );
};
