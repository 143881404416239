import "./LoaderHorizontal.modules.scss";

export const LoaderHorizontal = () => (
  <div className="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
