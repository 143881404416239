import React from "react";
import styles from "./OnboardingFormPasswordValidation.module.scss";
import Grid, { GridElem } from "../../../Grid/Grid";
import cn from "classnames";
import { ReactComponent as IconCheck } from "../../../../assets/icons/check.svg";

interface IOnboardingFormPasswordValidation {
  charLength: boolean;
  upperLower: boolean;
  number: boolean;
}

const OnboardingFormPasswordValidation = (props: IOnboardingFormPasswordValidation) => {
  const { charLength, upperLower, number } = props;

  return (
    <div className={styles.element}>
      <Grid>
        <GridElem md={12}>
          <div className={styles.title}>Your password must have:</div>
        </GridElem>
        <GridElem md={12}>
          <SingleOnboardingFormPasswordValidation
            active={charLength}
            label="8 or more characters"
          />
          <SingleOnboardingFormPasswordValidation
            active={upperLower}
            label="Upper & lowercase letters"
          />
          <SingleOnboardingFormPasswordValidation active={number} label="At least one number" />
        </GridElem>
      </Grid>
    </div>
  );
};

export default OnboardingFormPasswordValidation;

const SingleOnboardingFormPasswordValidation = ({
  active,
  label,
}: {
  active: boolean;
  label: string;
}) => {
  return (
    <div
      data-testid="validateInfo"
      className={cn(styles.item, active && styles["isActive"], "d-flex align-items-center")}
    >
      <IconCheck className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </div>
  );
};
