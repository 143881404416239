import { useEffect, useState } from "react";
import styles from "../CancelMembership.module.scss";
import amplitude from "amplitude-js";
import cs from "classnames";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";

interface IProps {
  setStep: (step: number) => void;
  setChooseAffordableOption: (value: boolean) => void;
  setTypeAffordable: (value: string) => void;
  handleSubmit: (wantsALaCarte: boolean) => void;
  handleChange: (wantsMessagingOnly: boolean) => void;
  eligibleForMaintenanceMode?: boolean;
  eligibleForALaCarte?: boolean;
}
interface IOption {
  id: number;
  title: string;
  description?: string;
  membership?: string;
}

export const DowngradeStep = ({
  setStep,
  setChooseAffordableOption,
  setTypeAffordable,
  handleSubmit,
  handleChange,
  eligibleForMaintenanceMode,
  eligibleForALaCarte,
}: IProps) => {
  const [active, setActive] = useState<number>(0);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const generateDowngradeOptions = (): IOption[] => {
    const options: IOption[] = [
      {
        id: 3,
        title: "No, please cancel.",
      },
    ];

    if (eligibleForALaCarte) {
      const eligibleForALaCarteOption = {
        id: 1,
        title: "Visit-only",
        description:
          "Book video appointments for our standard appointment fee. You will no longer get access to care coaching.",
        membership: "No membership required",
      };
      options.splice(0, 0, eligibleForALaCarteOption);
    }

    if (eligibleForMaintenanceMode) {
      const maintenanceModeOption = {
        id: 2,
        title: "Maintenance Plan",
        description:
          "Message with your care team for medical questions and refills. Check in with your doctor annually.",
        membership: isResponsiveView ? "$10/mo billed/qtr" : "$10/mo billed quarterly",
      };
      if (!eligibleForALaCarte) options.splice(0, 0, maintenanceModeOption);
      else options.splice(1, 0, maintenanceModeOption);
    }

    return options;
  };

  const handleClick = () => {
    if (active === 3) {
      setStep(3);
      return;
    }
    setChooseAffordableOption(true);
    const typeAffordable = active === 1 ? "visit-only" : "maintenance-plan";
    setTypeAffordable(typeAffordable);

    const isMessengingOnly = active === 2;

    if (isMessengingOnly) amplitude.getInstance().logEvent("moved_to_messaging_only");
    if (!isMessengingOnly) amplitude.getInstance().logEvent("moved_to_visits_only");

    handleSubmit(!isMessengingOnly);
  };

  useEffect(() => {
    const isMessengingOnly = active === 2;
    handleChange(isMessengingOnly);
  }, [active]);

  useEffect(() => {
    if (eligibleForALaCarte && eligibleForMaintenanceMode) {
      amplitude.getInstance().logEvent("cancellation_offered_visits_and_messaging");
    }
    if (!eligibleForALaCarte && eligibleForMaintenanceMode) {
      amplitude.getInstance().logEvent("cancellation_offered_messaging_only");
    }
    if (eligibleForALaCarte && !eligibleForMaintenanceMode) {
      amplitude.getInstance().logEvent("cancellation_offered_visits_only");
    }
  }, [eligibleForMaintenanceMode, eligibleForALaCarte]);

  return (
    <div className={styles.downgrade}>
      <>
        {generateDowngradeOptions().map((option) => (
          <div
            key={option.id}
            className={cs(
              styles.downgradeOption,
              active === option.id && styles.downgradeOptionActive
            )}
            onClick={() => setActive(option.id)}
          >
            <h3 className={styles.downgradeOptionTitle}>{option.title}</h3>
            {option.description && (
              <p className={styles.downgradeOptionDescription}>{option.description}</p>
            )}
            {option.membership && (
              <p className={styles.downgradeOptionMembership}>{option.membership}</p>
            )}
          </div>
        ))}
      </>

      <button className={styles.contentFormButton} onClick={handleClick}>
        Next
      </button>
    </div>
  );
};
