import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import amplitude from "amplitude-js";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as LogoDesktop } from "../../assets/images/svg/logo.svg";
import { ReactComponent as LogoMobile } from "../../assets/images/svg/logo-header-mobile.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/paymentsCard.svg";
import { getNavMenu } from "../../helpers/getNavMenu";

import Container from "../Container/Container";
import Burger from "../Burger/Burger";
import StartTodayButton from "../StartTodayButton/StartTodayButton";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import styles from "./Header.module.scss";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";

interface IHeaderProps {
  step?: number;
  uid?: string | null;
  patientData?: any;
  setLoading?: any;
}

const Header = ({ step, uid, setLoading }: IHeaderProps) => {
  const history = useHistory();
  const menu = getNavMenu();
  const { pathname } = useLocation();

  const [sticky, setSticky] = useState(false);
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["lg"]}px)`,
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const openStripePortal = async function stripePortal() {
    setLoading(true);

    const functions = new FirebaseApi().firebaseFunctions();

    window.location.href = await functions.createCustomerPortalSession({
      // @ts-ignore
      patientID: uid,
      isTest: !(process.env.REACT_APP_ENV === "production"),
    });
  };

  const redirectToOnboarding = () => {
    amplitude.getInstance().logEvent("nav_bar_start_today_click");
    // @ts-ignore
    window?.dataLayer.push({
      event: "nav_bar_start_today_click",
    });

    history.push({ pathname: "/onboarding", search: window.location.search });
  };

  const redirectToLogin = () => {
    amplitude.getInstance().setUserId(null);

    // @ts-ignore
    Kustomer.logout((_callbackResponse, _error) => {
      // @ts-ignore
      Kustomer.stop();
    });

    firebase.auth().signOut();
    history.push({ pathname: "/login", search: window.location.search });
    window.location.reload();
  };

  const burgerMenuSwitcher = () => {
    setBurgerMenuIsOpen((prevValue) => !prevValue);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSticky(window.scrollY > 50);
      window.addEventListener("scroll", () => {
        setSticky(window.scrollY > 50);
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={cn(styles.element, sticky && styles["isSticky"])}>
      <BurgerMenu
        isOpen={burgerMenuIsOpen}
        onClose={burgerMenuSwitcher}
        startButtonClick={redirectToOnboarding}
      />
      <Container className={styles.mainContainer}>
        <div className={cn(styles.inner, "d-flex align-items-center")}>
          {isResponsiveView && <Burger onClick={() => burgerMenuSwitcher()} />}

          <a
            className={cn(styles.logo, "d-inline-flex")}
            href={`https://neurahealth.co${window.location.search}`}
          >
            {isResponsiveView ? <LogoMobile /> : <LogoDesktop />}
          </a>

          {!isResponsiveView && (
            <ul className={cn(styles.menu, "d-flex align-items-center")}>
              {menu.map((elem, key) => (
                <li className={cn(styles.item, "d-inline-flex")} key={key}>
                  <a
                    href={`https://neurahealth.co${elem.to}${window.location.search}`}
                    className={cn(styles.link, {
                      [styles.isActive]: window.location.pathname === elem.to,
                    })}
                    onClick={() => {
                      amplitude.getInstance().logEvent(elem.event);
                      // @ts-ignore
                      window?.dataLayer.push({
                        event: elem.event,
                      });
                    }}
                  >
                    {elem.title}
                  </a>
                </li>
              ))}
            </ul>
          )}

          {(pathname === "/onboarding" || pathname === "/thankyou" || pathname === "/login") &&
            step === 5 && (
              <>
                {!smallScreen && (
                  <button
                    className={cn(styles.link, styles.paymentSettings)}
                    onClick={openStripePortal}
                  >
                    <CardIcon className={styles.icon} />
                    Payment settings
                  </button>
                )}

                <StartTodayButton text="Log out" fixedHeader={sticky} onClick={redirectToLogin} />
              </>
            )}

          {pathname !== "/onboarding" && pathname !== "/thankyou" && pathname !== "/login" && (
            <div className={styles.signUpContainer}>
              <Link
                to={{ pathname: "/login", search: window.location.search }}
                className={cn(styles.link, styles.login)}
                onClick={() => {
                  // @ts-ignore
                  window?.dataLayer.push({
                    event: "nav_bar_login_click",
                  });
                  amplitude.getInstance().logEvent("nav_bar_login_click");
                }}
              >
                Login
              </Link>

              <StartTodayButton
                text="Start today"
                onClick={redirectToOnboarding}
                fixedHeader={sticky}
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
export default Header;
