import { useHistory } from "react-router-dom";
import amplitude from "amplitude-js";
import firebase from "firebase/compat/app";

export const useLogout = () => {
  const history = useHistory<any>();

  const logout = async () => {
    amplitude.getInstance().setUserId(null);

    // @ts-ignore
    Kustomer.logout((_callbackResponse, _error) => {
      // @ts-ignore
      Kustomer.stop();
    });

    await firebase.auth().signOut();
    history.push({ pathname: "/login", search: window.location.search });
  };

  return { logout };
};
