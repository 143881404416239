import { useState } from "react";
import { ReactComponent as User } from "../../assets/images/svg/user.svg";
import { ReactComponent as Wallet } from "../../assets/images/svg/wallet.svg";
import { ReactComponent as Paper } from "../../assets/images/svg/paper.svg";
import { ReactComponent as Logout } from "../../assets/images/svg/logout.svg";
import { ReactComponent as MedicinesIcon } from "../../assets/icons/medicines-menu.svg";
import styles from "./MenuDropDown.module.scss";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import "firebase/compat/auth";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Loader from "../LoaderSpinner/LoaderSpinner";
import { useLogout } from "../../hooks/useLogout";

const infoBlock = [
  { icon: <User />, text: "Basic info", activeTab: 1 },
  { icon: <Paper />, text: "My forms", activeTab: 2 },
  {
    icon: <MedicinesIcon />,
    text: "Request Refill",
    openModal: true,
  },
  {
    icon: <Wallet />,
    text: "Payment Settings",
    activeTab: 3,
    hideForEnterpriseUser: true,
  },
  {
    icon: <Wallet />,
    text: "Update Payment",
    hideForD2cUser: true,
  },
  { icon: <Logout />, text: "Sign out", isLogout: true },
];

export default function MenuDropDown(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory<any>();

  const { logout } = useLogout();

  const openStripePortal = async function stripePortal() {
    const functions = new FirebaseApi().firebaseFunctions();
    setIsLoading(true);
    const url = await functions.createCustomerPortalSession({
      // @ts-ignore
      patientID: props.patientData.uid,
      isTest: !(process.env.REACT_APP_ENV === "production"),
    });
    if (url) {
      setIsLoading(false);
      window.open(url, "_blank");
    } else {
      setIsLoading(false);
    }
  };

  function clickHandler(item: any) {
    if (props.patientData.partnerName && item.text === "Update Payment") {
      openStripePortal();
      return;
    }
    if (item.activeTab && !item.openModal) {
      history.push({
        pathname: "/user",
        state: { activeTab: item.activeTab },
      });
      props.handleClose();
      return;
    }

    if (item.openModal) {
      props.handleClose();
      props.openPrescriptionModal();
      return;
    }

    if (item.isLogout) {
      logout();
      return;
    }
  }

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <div
      ref={props.node}
      className={classNames(
        styles.userInfo,
        props.isCalledFromTop ? styles.inVisible : styles.positioned
      )}
    >
      {infoBlock
        .filter((item) =>
          props.patientData.partnerName ? !item.hideForEnterpriseUser : !item.hideForD2cUser
        )
        .map((item) => (
          <div className={styles.userInfoItem} key={item.text} onClick={() => clickHandler(item)}>
            {item.icon}
            <button className={styles.userInfoText}>{item.text}</button>
          </div>
        ))}
    </div>
  );
}
