import { useState } from "react";

export const useCheckScrollPositionIsBottom = () => {
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const scrollPositionBottom = (event: React.UIEvent<HTMLDivElement>) => {
    const { currentTarget } = event;
    if (currentTarget.scrollHeight - currentTarget.scrollTop === currentTarget.clientHeight) {
      setScrollIsBottom(true);
    } else {
      setScrollIsBottom(false);
    }
  };

  return { scrollIsBottom, scrollPositionBottom };
};
