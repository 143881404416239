import React from "react";
import cn from "classnames";
import { createPortal } from "react-dom";
import { IModal } from "./Modal.interface";
import Container from "../Container/Container";
import styles from "./Modal.module.scss";

const Modal = ({ children, isBenefits, smallScreen, isWide, handleClose }: IModal) => {
  const ModalTemplate = (
    <div
      onClick={handleClose}
      className={cn(styles.element, {
        [styles.elementBenefits]: isBenefits && smallScreen,
      })}
    >
      <Container className={cn({ [styles.isBenefits]: isBenefits })}>
        <div
          className={cn({
            [styles.dialog]: !isBenefits,
            [styles.dialogBenefits]: isBenefits,
            [styles.wideDialog]: isWide,
          })}
        >
          {children}
        </div>
      </Container>
    </div>
  );

  return createPortal(ModalTemplate, document.body);
};

export default Modal;
