import React from "react";
import styles from "./TrackHeadache.module.scss";
import QRComponent from "../QRComponent/QRComponent";
import { ReactComponent as Play } from "../../assets/images/svg/play.svg";
import { ReactComponent as Apple } from "../../assets/images/svg/apple.svg";
import amplitude from "amplitude-js";

const downloadLinkIcons = [
  { icon: <Apple />, platform: "ios" },
  { icon: <Play />, platform: "android" },
];

export default function TrackHeadache() {
  const downloadEvent = (event: string, item: any) => {
    // @ts-ignore
    window?.dataLayer.push({
      event: event,
    });
    amplitude.getInstance().logEvent(event);

    window.open(
      item.platform === "ios"
        ? "https://apps.apple.com/us/app/id1562391775"
        : "https://play.google.com/store/apps/details?id=com.gezuntpatient",
      "_blank"
    );
  };

  return (
    <div className={styles.track}>
      <div>
        <div className={styles.trackTitle}>Download our app to track your headaches</div>
        <QRComponent />
        <div className={styles.trackIcons}>
          {downloadLinkIcons.map((item: any, index: number) => (
            <button
              key={`${new Date()}${index}`}
              onClick={() => downloadEvent(`onboarding_v2_download_${item.platform}_click`, item)}
            >
              {item.icon}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
