import styles from "./FeedbackMessageCard.module.scss";
import Heading from "../Heading/Heading";
import Button from "../Button/Button";
import { ReactComponent as LeafLeftIcon } from "../../assets/images/svg/feedback_card_leaf_left.svg";
import { ReactComponent as LeafRightIcon } from "../../assets/images/svg/feedback_card_leaf_right.svg";

interface IProps {
  title: string;
  subtitle: string;
  buttonLabel: string;
  redirect: () => void;
}

export const FeedbackMessageCard = ({ title, subtitle, buttonLabel, redirect }: IProps) => (
  <div className={styles.card}>
    <LeafLeftIcon className={styles.leftIcon} />
    <LeafRightIcon className={styles.rightIcon} />
    <Heading title={title} size="h1" />
    <Heading title={subtitle} size="h3" className={styles.subHeading} />
    <Button tag="button" onClick={redirect} title={buttonLabel} className={styles.button} />
  </div>
);
