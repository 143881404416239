import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./ThankYouPage.module.scss";
import OnboardingFormSubmit from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { conditions } from "../../utils/conditionFocus";
import { DotsOfStep } from "../../components/DotsOfStep/DotsOfStep";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";

import { ReactComponent as Logo } from "../../assets/images/svg/logo.svg";
// import welcomeMobileSrc from "../../assets/images/WelcomeMobile.png";
import { ReactComponent as ExpertsIcon } from "../../assets/images/experts.svg";
import { ReactComponent as ExpertsMobileIcon } from "../../assets/images/expertsMobile.svg";
import { ReactComponent as WelcomeIcon } from "../../assets/images/Welcome.svg";
import { ReactComponent as WelcomeMobileIcon } from "../../assets/images/WelcomeMobile.svg";
import { ReactComponent as CarePlanIcon } from "../../assets/images/carePlan.svg";
import { ReactComponent as CarePlanMobileIcon } from "../../assets/images/carePlanMobile.svg";
import { ReactComponent as ConceirgeIcon } from "../../assets/images/Conceirge.svg";
import { ReactComponent as ConceirgeMobileIcon } from "../../assets/images/ConceirgeMobile.svg";
import { ReactComponent as TrendsIcon } from "../../assets/images/trends.svg";

export const ThankYouPage = ({ user }: any) => {
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState("");
  const [conditionFocus, setConditionFocus] = useState<string[]>([]);

  const history = useHistory();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const isHeadache = conditionFocus.includes(conditions.HEADACHE_MIGRAINE);

  let firestore = firebase.firestore;

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return {
          image: isResponsiveView ? <WelcomeMobileIcon /> : <WelcomeIcon />,
          heading: "Welcome to Neura Health,",
          subheading:
            "Our mission is to improve access to and quality of care for neurological conditions",
        };
      case 2:
        return {
          image: isResponsiveView ? (
            <ExpertsMobileIcon className={styles.careTeamIcon} />
          ) : (
            <ExpertsIcon />
          ),
          heading: "Meet with experts to understand your condition",
          subheading: "Book virtual visits with licensed neurologists and care coaches.",
        };
      case 3:
        return {
          image: isResponsiveView ? (
            <CarePlanMobileIcon className={styles.carePlanIcon} />
          ) : (
            <CarePlanIcon className={styles.carePlanIcon} />
          ),
          heading: "Get a personalized care plan to take control of your health",
          subheading: "Get guidance on your path to better health with support from your coach.",
        };
      case 4:
        return {
          image: isResponsiveView ? (
            <ConceirgeMobileIcon style={{ marginTop: "30px" }} />
          ) : (
            <ConceirgeIcon style={{ marginTop: "30px" }} />
          ),
          heading: "Care concierge available by chat 24/7",
          subheading:
            "From referrals to prior authorizations, we’ll help get it sorted quickly and easily.",
        };
      case 5:
        return {
          image: <TrendsIcon className={styles.trendsIcon} />,
          heading: "Discover trends by easily tracking your attacks",
          subheading: "Record attacks to better understand your triggers and symptoms.",
        };
    }
  };

  const buttonContent = () => {
    if (isHeadache && step === 5) {
      return "Let's get started!";
    } else if (!isHeadache && step === 4) {
      return "Let's get started!";
    } else {
      return "Next";
    }
  };

  const handleNextStep = () => {
    if (isHeadache && step === 5) {
      history.push("/home");
    } else if (!isHeadache && step === 4) {
      history.push("/home");
    } else {
      setStep(step + 1);
    }
  };

  useEffect((): any => {
    let subscription: Promise<void>;
    if (user.uid) {
      subscription = firestore()
        .collection("patients")
        .doc(user.uid)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();
          setUserName(`${data?.firstName}`);
          if (data?.conditionFocus) setConditionFocus(data.conditionFocus);
          else setConditionFocus([]);
        });
    }

    return () => subscription;
  }, [firestore, user.uid]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.backgroundCircleTop}></div>
        <div className={cn(styles.logo, "d-inline-flex")}>
          <Logo />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.wrapperImages}>{renderStepContent()?.image}</div>
        </div>
        <div className={styles.wrapperContent}>
          <div className={styles.wrapperHeading}>
            <h4 className={styles.heading}>{renderStepContent()?.heading}</h4>
            {step === 1 && <h4 className={styles.heading}>{userName}</h4>}
          </div>

          <p className={styles.bodyOne}>{renderStepContent()?.subheading}</p>
        </div>

        <div className={`${styles.wrapperSubmitButton} "d-flex justify-content-center"`}>
          <OnboardingFormSubmit title={buttonContent()} onClick={handleNextStep} />
        </div>
        <div className={styles.wrapperSteps}>
          <DotsOfStep isFiveSteps={isHeadache} step={step} />
        </div>
      </div>
    </>
  );
};
