import styles from "./Tabs.module.scss";
import classNames from "classnames";

export interface ITab {
  title: string;
  id: number;
}

interface IProps {
  tabs: ITab[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  children: React.ReactNode;
}

export const Tabs = ({ tabs, activeTab, setActiveTab, children }: IProps) => {
  return (
    <div className={styles.tabs}>
      <ul className={styles.tabsTop}>
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className={classNames(styles.tab, activeTab === tab.id && styles.active)}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </li>
        ))}
        <div className={styles.tabsTopBorder} />
      </ul>
      <div className={styles.tabsContent}>{children}</div>
    </div>
  );
};
