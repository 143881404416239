/**
 * Generate a 15-byte/20-character base64-like user password.
 *
 * @returns the generated user password
 */
export const generateUserPassword = (): string => {
  return btoa(
    Array.from(crypto.getRandomValues(new Uint8Array(15)), (byte) =>
      String.fromCodePoint(byte)
    ).join("")
  );
};
