import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { History } from "history";

const sessionCheck = async (
  step: number | undefined,
  setFirstStep: any,
  setLoginMessage: (message: string) => void,
  history: History
) => {
  try {
    const session = await firebase.auth().currentUser;

    if (!session) {
      step !== 0 && setLoginMessage("Session expired. Please log in again.");

      history.push({ pathname: "/login", search: window.location.search });
      setFirstStep();

      return false;
    }

    return session;
  } catch (error) {
    step !== 0 && setLoginMessage("Session expired. Please log in again.");

    history.push({ pathname: "/login", search: window.location.search });
    setFirstStep();

    return false;
  }
};

export default sessionCheck;
