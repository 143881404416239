import React, { ReactNode, ReactNodeArray } from "react";
import cn from "classnames";
import styles from "./Heading.module.scss";

const Heading = (props: IHeading) => {
  const { size = "h2", title, className } = props;

  return (
    <div className={cn(styles.element, styles[`is-${size}`], className && className)}>
      <span>{title}</span>
    </div>
  );
};
export default Heading;

interface IHeading {
  size?: "h1" | "h2" | "h3" | "h4";
  title: string | ReactNode | ReactNodeArray;
  className?: string;
}
