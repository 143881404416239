import React from "react";
import { useLocation } from "react-router-dom";

const OnboardingHoc = (props: any) => {
  const { Component } = props;
  const { pathname } = useLocation();

  return <Component {...props} pathname={pathname} />;
};

export default OnboardingHoc;
