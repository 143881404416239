import { useState, useContext, createContext } from "react";

interface IProps {
  children: React.ReactNode;
}

interface IRefreshPlanItemCountContext {
  refreshPlanItemCount: boolean;
  setRefreshPlanItemCount: (refreshPlanItemCount: boolean) => void;
}

const RefreshPlanItemCountContext = createContext<IRefreshPlanItemCountContext | null>(null);

export const RefreshPlanItemCountProvider = ({ children }: IProps) => {
  const [refreshPlanItemCount, setRefreshPlanItemCount] = useState(false);

  return (
    <RefreshPlanItemCountContext.Provider value={{ refreshPlanItemCount, setRefreshPlanItemCount }}>
      {children}
    </RefreshPlanItemCountContext.Provider>
  );
};

export const useRefreshPlanItemCountContext = () => {
  const context = useContext(RefreshPlanItemCountContext);

  if (!context) {
    throw new Error("Error from RefreshPlanItemCountProvider context api");
  }

  return context;
};
