import React, { useEffect, useState } from "react";
import { FieldInputProps, FormikProps } from "formik";
import cn from "classnames";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";

import styles from "./OnboardingFormTextInput.module.scss";

interface IOnboardingFormTextInputPhone {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label: string;
  placeholder?: string;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
}

const OnboardingFormTextInputPhone = (props: IOnboardingFormTextInputPhone) => {
  const { field, form, label, placeholder, inputMode = "numeric" } = props;

  const [focus, setFocus] = useState(!!field.value);

  useEffect(() => {
    if (field.value !== "") {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <div
          className={cn(
            styles.element,
            focus && styles["isFocused"],
            field.value !== "" && !form.errors[field.name] && styles["isValid"],
            form.touched[field.name] && form.errors[field.name] && styles["isAlert"],
            placeholder && styles["hasPlaceholder"]
          )}
        >
          <span className={styles.label}>{label}</span>

          <input
            type="text"
            inputMode={inputMode}
            className={styles.input}
            {...field}
            onFocus={() => setFocus(true)}
            onBlur={(e) => {
              field.onBlur(e);
              field.value === "" && setFocus(false);
            }}
            onChange={(e) => {
              form.setFieldValue(
                field.name,
                e.currentTarget.value.replace(/(\d{3})[-]?(\d{3})[-]?(\d{4})/, "$1-$2-$3")
              );
            }}
            placeholder={placeholder}
          />
        </div>
      </div>
      {form.touched[field.name] && form.errors[field.name] && (
        <OnboardingFormError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};

export default OnboardingFormTextInputPhone;
