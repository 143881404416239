import styles from "./DotsOfStep.module.scss";

interface IProps {
  step: number;
  isFiveSteps: boolean;
}

export const DotsOfStep = ({ step, isFiveSteps }: IProps) => {
  const steps: number[] = [1, 2, 3, 4];

  if (isFiveSteps) steps.push(5);

  return (
    <div className={styles.root}>
      {steps.map((item, index) => (
        <div className={`${styles.step} ${step === index + 1 && styles.active}`} key={index}></div>
      ))}
    </div>
  );
};
