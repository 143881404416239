const HUBSPOT_CREATE_USER_FORM_URL =
  "https://api.hsforms.com/submissions/v3/integration/submit/19952123/399371b7-df7c-4a43-875d-cba24039a547";

export async function createHubspotUser(
  email: string,
  uid: string,
  sourcePropName: string,
  sourcePropValue: string
) {
  let xhr = new XMLHttpRequest();

  // @ts-ignore
  const cookie = document?.cookie
    ?.split(" ")
    ?.find((el: any) => el?.includes("hubspotutk"))
    .split("hubspotutk=")[1]
    .split(";")[0];

  const data = {
    fields: [
      {
        name: "email",
        value: email,
      },
      {
        name: "firestore_id",
        value: uid,
      },
      {
        name: sourcePropName,
        value: sourcePropValue,
      },
    ],
    context: {
      hutk: cookie, // include this parameter and set it to the hubspotutk cookie value
      pageUri: "www.neurahealth.co/onboarding",
      pageName: "Neurahealth - OnboardingFormSectionMemberShip",
    },
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", HUBSPOT_CREATE_USER_FORM_URL);
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(final_data);
}
