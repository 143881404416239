import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import ONBOARDING_MEMBERSHIP_LIST from "../../../helpers/OnboardingModalList";
import styles from "./OnboardingFormSectionMemberShip.module.scss";
import { ReactComponent as Icon6 } from "../../../assets/images/svg/onboardingMemberShip/onboarding-icon6.svg";
import { ReactComponent as Icon5 } from "../../../assets/images/svg/onboardingMemberShip/onboarding-icon51.svg";
import { ReactComponent as HeaderLogo } from "../../../assets/images/svg/logo-header-mobile.svg";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import amplitude from "amplitude-js";

const OnboardingMemberShipModal = ({
  setModalOpen,
  isModalOpen,
  isSmallScreen,
}: {
  setModalOpen: any;
  isModalOpen?: any;
  isSmallScreen?: any;
}) => {
  return (
    <>
      {isModalOpen && isSmallScreen && (
        <div className={styles.logoContainer}>
          <a href={`https://neurahealth.co${window.location.search}`}>
            <HeaderLogo />
          </a>
        </div>
      )}

      <div className="d-flex justify-content-center">
        <OnboardingFormHeading className={styles.header} title={`Neura Membership Benefits`} />
      </div>

      <Icon5 className={styles.leftIcon} />

      <Icon6 className={styles.backgroundImage} />

      <div className={styles.wrapper}>
        {ONBOARDING_MEMBERSHIP_LIST.map((list) => (
          <div key={list.name} className={cn(styles.box, "d-flex align-items-center")}>
            <div className={cn(styles.icons)}>
              <img src={list.image} alt="" />
            </div>

            <p className={styles.desc}>{list.name}</p>
          </div>
        ))}

        <div className={cn(styles.buttonWrapper, "d-flex justify-content-end")}>
          <OnboardingFormSubmit
            onClick={() => {
              // @ts-ignore
              window?.dataLayer?.push({
                event: "onboarding_v2_get_started_click",
              });

              amplitude.getInstance().logEvent("onboarding_v2_get_started_click");

              setModalOpen();
            }}
            title="Continue"
          />

          <OnboardingFormDescription
            className={styles.logInText}
            text={
              <span>
                Already have an account?{" "}
                <Link
                  className={styles.link}
                  to={{ pathname: "/login", search: window.location.search }}
                  // onClick={(event) => isSubmitting && event.preventDefault()}
                >
                  Log in
                </Link>
              </span>
            }
          />
        </div>
      </div>
    </>
  );
};

export default OnboardingMemberShipModal;
