import React from "react";
import { ReactComponent as AppStore } from "../../assets/images/svg/appstore-label.svg";
import styles from "./AppStoreButton.module.scss";
import cn from "classnames";

interface IAppStoreButton {
  black?: boolean;
  className?: string;
  onClick?: () => void;
}

const AppStoreButton = ({ black, className, onClick }: IAppStoreButton) => {
  return (
    <a
      href="https://apps.apple.com/us/app/id1562391775"
      className={cn(
        styles.element,
        className,
        black && styles["isBlack"],
        "d-inline-flex justify-content-center align-items-center"
      )}
      onClick={onClick}
    >
      <AppStore />
    </a>
  );
};

export default AppStoreButton;
