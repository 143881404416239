import { useState } from "react";
import firebase from "firebase/compat/app";

import { IPatient, IPlanForToday } from "../types/patient";

import { CarePlanItemCompletionStatus } from "../utils/carePlantUtil";
import { useRefreshPlanItemCountContext } from "../context/refreshPlanItemCount";

export const useUpdatePlanItemStatus = (patientData: IPatient) => {
  const [explosion, setExplosion] = useState(false);

  let firestore = firebase.firestore;
  const { refreshPlanItemCount, setRefreshPlanItemCount } = useRefreshPlanItemCountContext();

  const { COMPLETE, INCOMPLETE } = CarePlanItemCompletionStatus;

  const updatePlanItemStatus = async (
    event: React.MouseEvent<HTMLInputElement>,
    uniqueID: string,
    status: number
  ) => {
    event.stopPropagation();

    const copyPlanForToday = [...patientData.planForToday] as IPlanForToday[];

    const foundItem = copyPlanForToday.find((plan) => plan.uniqueID === uniqueID);
    const foundIndex = copyPlanForToday.findIndex((plan) => plan.uniqueID === uniqueID);

    if (foundItem && foundItem.status === INCOMPLETE) setExplosion(true);

    if (patientData.uid && foundItem) {
      foundItem.status = status !== COMPLETE ? COMPLETE : INCOMPLETE;
      copyPlanForToday.splice(foundIndex, 1, foundItem);

      await updatePlanForToday(copyPlanForToday);
    }
  };

  const updatePlanForToday = async (planForToday: IPlanForToday[]) => {
    await firestore()
      .collection("patients")
      .doc(patientData.uid)
      .update({ planForToday })
      .then(() => {
        setRefreshPlanItemCount(!refreshPlanItemCount);
      });
  };

  return {
    updatePlanItemStatus,
    explosion,
  };
};
