import firebase from "firebase/compat/app";
import "firebase/compat/auth";

interface IFirebaseAuth {
  resetPassword(email: string): void;
  signUp(email: string, password: string): Promise<firebase.auth.UserCredential>;
  logIn(email: string, password: string): Promise<firebase.auth.UserCredential>;
}

class FirebaseAuth implements IFirebaseAuth {
  private auth: firebase.auth.Auth;

  constructor() {
    this.auth = firebase.auth();
  }

  async signUp(email: string, password: string) {
    sessionStorage.setItem("password", password);
    return this.auth.createUserWithEmailAndPassword(email, password).then((res) => res);
  }

  async logIn(email: string, password: string) {
    sessionStorage.setItem("password", password);
    return this.auth.signInWithEmailAndPassword(email, password).then((res) => res || null);
  }

  verifyEmail() {
    const user = this.auth.currentUser;

    if (user) {
      user.sendEmailVerification();
    }
  }

  async resetPassword(email: string) {
    return await this.auth.sendPasswordResetEmail(email);
  }
}

export default FirebaseAuth;
