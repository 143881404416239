import { Field, Form, Formik } from "formik";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import * as Yup from "yup";
import OnboardingFormTextInputPhone from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInputPhone";
import OnboardingFormTextInput from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import styles from "./OnboardingFormSectionReferral.module.scss";
import { ReactComponent as Leafs1 } from "../../../assets/images/svg/onboardingSectionPayment/leafs.svg";
import { ReactComponent as Leafs2 } from "../../../assets/images/svg/leafs.svg";
import { ReactComponent as BackgroundIcon1 } from "../../../assets/images/svg/onboarding-personal-data-background-icon.svg";
import { ReactComponent as BackgroundIcon2 } from "../../../assets/images/svg/onboarding-form-referral-icon.svg";
import sessionCheck from "../../../helpers/sessionCheck";
import { useHistory } from "react-router";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";

type OnboardingFormSectionReferralProps = IOnboardingFormSection & {};

type FormValues = {
  b2bReferrer: string;
  b2bReferrerPhone: string;
};

export const OnboardingFormSectionReferral = ({
  uid,
  formData,
  step,
  setFirstStep,
  setLoginMessage,
  setNextStep,
  setFormData,
  userEmail,
}: OnboardingFormSectionReferralProps) => {
  const history = useHistory();

  const onSubmit = async ({ b2bReferrer, b2bReferrerPhone }: FormValues) => {
    const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);
    if (!session) {
      return;
    }
    const firestore = new FirebaseApi().firebaseFirestore();

    setFormData({
      ...formData,
      b2bReferrer: b2bReferrer.trim(),
      b2bReferrerPhone: b2bReferrerPhone.trim(),
    });

    await firestore.updatePatient({
      data: {
        uid: uid || formData?.uid,
        b2bReferrer: b2bReferrer.trim(),
        b2bReferrerPhone: b2bReferrerPhone.trim(),
      },
    });

    try {
      const functions = new FirebaseApi().firebaseFunctions();

      functions.updateHubspotContact("b2b_referrer", {
        email: formData?.email || userEmail,
        eventValue: b2bReferrer,
      });

      functions.updateHubspotContact("b2b_referrer_phone", {
        email: formData?.email || userEmail,
        eventValue: b2bReferrerPhone,
      });
    } catch (error) {
      console.log(error);
    }

    setNextStep();
  };

  return (
    <Formik
      initialValues={{
        b2bReferrer: formData?.b2bReferrer || "",
        b2bReferrerPhone: formData?.b2bReferrerPhone || "",
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        b2bReferrer: Yup.string().required("Referrer is required."),
        b2bReferrerPhone: Yup.string()
          .matches(/^[0-9]{3}[-][0-9]{3}[-][0-9]{4}$/, "Phone number is invalid.")
          .required("Phone number is required."),
      })}
    >
      {({ isValid, isSubmitting }) => (
        <Form className={styles.form}>
          <OnboardingFormHeading className={styles.title} title="How did you hear about us?" />
          <OnboardingFormDescription
            className={styles.description}
            text="We will not share any information with them without your consent"
          />
          <div className={styles.fields}>
            <Field
              label="Name of referrer"
              name="b2bReferrer"
              component={OnboardingFormTextInput}
            />
            <Field
              inputMode="numeric"
              label="Phone number"
              name="b2bReferrerPhone"
              component={OnboardingFormTextInputPhone}
              placeholder="XXX-XXX-XXXX"
              validateOnChange
            />
            <Leafs1 className={styles.leaf1} />
            <Leafs2 className={styles.leaf2} />
            <BackgroundIcon1 className={styles.backgroundIcon1} />
            <BackgroundIcon2 className={styles.backgroundIcon2} />
          </div>
          <OnboardingFormSubmit
            className={styles.submitButton}
            title="Next"
            disabled={!isValid}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};
