import Button from "../../../Button/Button";

import { conditions } from "../../../../utils/conditionFocus";
import { conditionType } from "../../../../types/condition";

import styles from "../OnboardingFormSelectionCondition.module.scss";

import { ReactComponent as HeadphonesIcon } from "../../../../assets/images/svg/headphones-human.svg";
import { ReactComponent as MoonIcon } from "../../../../assets/images/svg/moon-star.svg";
import { ReactComponent as WavesIcon } from "../../../../assets/images/svg/waves.svg";
import { ReactComponent as ConcussionIcon } from "../../../../assets/images/svg/concussion.svg";
import { ReactComponent as StrokeIcon } from "../../../../assets/images/svg/stroke.svg";
import { ReactComponent as TremorIcon } from "../../../../assets/images/svg/tremor.svg";
import { ReactComponent as UndiagnosedIcon } from "../../../../assets/images/svg/other_undiagnosed.svg";
import { partnersName } from "../../../../utils/partnerList";

interface IProps {
  type: conditionType;
  activeSelect: (option: conditionType) => void;
  handleSelect: (option: conditionType) => void;
  displayIcon: (option: conditionType) => void;
  activeTextButton: (option: conditionType) => void;
  partner?: string;
}

export const Card = ({
  type,
  activeSelect,
  handleSelect,
  displayIcon,
  activeTextButton,
  partner,
}: IProps) => {
  const titles = {
    [conditions.HEADACHE_MIGRAINE]: "Headache/Migraine",
    [conditions.SLEEP]: "Sleep",
    [conditions.EPILEPSY]: "Epilepsy",
    [conditions.CONCUSSION_TBI]: "Concussion/TBI",
    [conditions.STROKE]: "Stroke",
    [conditions.TREMOR]: "Tremor",
    [conditions.OTHER_UNDIAGNOSED]: "Other/Undiagnosed (Including Long Covid)",
  };

  const descriptions = {
    [conditions.HEADACHE_MIGRAINE]:
      "Our board-certified neurologists treat all headache disorders and migraine subtypes, no matter how rare.",
    [conditions.SLEEP]:
      "Our board-certified sleep specialists treat all sleep disorders, including insomnia and sleep apnea.",
    [conditions.EPILEPSY]: "Our board-certified epilepsy specialists treat seizures and epilepsy.",
    [conditions.CONCUSSION_TBI]:
      "Our board-certified neurologists treat mild and moderate concussion and traumatic brain injury (TBI), including post-concussion syndrome.",
    [conditions.STROKE]:
      "Our board-certified neurologists treat Transient Ischemic Attack (TIA) and stroke patients as they recover, to avoid further episodes.",
    [conditions.TREMOR]:
      "Our board-certified neurologists treat essential tremor, tic, and cervical dystonia.",
    [conditions.OTHER_UNDIAGNOSED]:
      partner === partnersName.SPORTS || partner === partnersName.ATHLETES
        ? "Our board-certified neurologists can perform neurology exams for sporting events, and help diagnose symptoms such as numbness, pain and more."
        : "Our board-certified neurologists can help diagnose neurological symptoms such as numbness, pain, dizziness and more.",
  };

  const icons = {
    [conditions.HEADACHE_MIGRAINE]: <HeadphonesIcon />,
    [conditions.SLEEP]: <MoonIcon />,
    [conditions.EPILEPSY]: <WavesIcon />,
    [conditions.CONCUSSION_TBI]: <ConcussionIcon />,
    [conditions.STROKE]: <StrokeIcon />,
    [conditions.TREMOR]: <TremorIcon />,
    [conditions.OTHER_UNDIAGNOSED]: <UndiagnosedIcon />,
  };

  return (
    <div className={`${styles.wrapper__card} ${activeSelect(type)}`}>
      {icons[type]}
      <div className={styles.wrapper__card__texts}>
        <h3 className={styles.heading}>{titles[type]}</h3>
        <p className={styles.description}>{descriptions[type]}</p>
      </div>

      <Button
        onClick={() => handleSelect(type)}
        className={`${styles.button}`}
        icon={displayIcon(type)}
        title={activeTextButton(type)}
        tag="button"
      />
    </div>
  );
};
