/* eslint-disable no-fallthrough */
import OnboardingFormSectionAddress from "../OnboardingFormSectionAddress/OnboardingFormSectionAddress";
import OnboardingFormSectionMedicalPersonal from "../OnboardingFormSectionMedicalPersonal/OnboardingFormSectionMedicalPersonal";
import OnboardingFormSectionPersonalData from "../OnboardingFormSectionPersonalData/OnboardingFormSectionPersonalData";
import OnboardingFormSelectionCondition from "../OnboardingFormSelectionCondition/OnboardingFormSelectionCondition";

import { IOnboardingForm } from "../OnboardingForm";

import { IFormData, ICoupon } from "../OnboardingFormSection.interface";
import { useHistory, useLocation } from "react-router";
import OnboardingFormSectionRegister from "../OnboardingFormSectionRegister/OnboardingFormSectionRegister";
import { OnboardingFormCreatePassword } from "../OnboardingFormCreatePassword/OnboardingFormCreatePassword";

import { useDefaultPasswordUpdate } from "../../../hooks/useDefaultPasswordUpdate";
import { useEffect, useState } from "react";
import { useOnboardingStepCountContext } from "../../../context/onboardingStepCount";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import { DocumentData } from "firebase/firestore";
import { OnboardingFormSectionReferral } from "../OnboardingFormSectionReferral/OnboardingFormSectionReferral";

interface IProps extends IOnboardingForm {
  formData?: IFormData;
  setFormData: (formData: any) => void;
  setFirstStep: () => void;
  coupon: ICoupon | null;
  localGenPw?: string | null;
  setLocalGenPw: (pwd: string) => void;
}

export const B2bFlow = ({
  uid,
  patientData,
  userEmail,
  formData,
  setFormData,
  step,
  setStep,
  setFirstStep,
  setLoading,
  partner,
  coupon,
  loginMessage,
  setLoginMessage,
  localGenPw,
  setLocalGenPw,
}: IProps) => {
  const { defaultPasswordUpdated, fetchDefaultPasswordUpdate } = useDefaultPasswordUpdate();

  const { setStepCount } = useOnboardingStepCountContext();
  const history = useHistory();
  const { state } = useLocation<any>();

  const [partnerData, setPartnerData] = useState<DocumentData | undefined>();

  const ComponentsProps = {
    uid,
    patientData,
    partnerData,
    userEmail,
    formData,
    setLoading,
    setFormData,
    step,
    setFirstStep,
    loginMessage,
    setLoginMessage,
    localGenPw,
  };

  useEffect(() => {
    if (!uid) return;
    fetchDefaultPasswordUpdate(uid);
  }, [uid, step]);

  useEffect(() => {
    let stepCount = 4;
    if (!defaultPasswordUpdated) {
      stepCount += 1;
    }
    if (partnerData?.onboardingRequireAttribution) {
      stepCount += 1;
    }
    setStepCount(stepCount);
  }, [partnerData]);

  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    const getStatesForPartner = async () => {
      // there should be no fallback partner for b2b onboarding flow
      const data = await firestore.getPartnerData(partner);
      setPartnerData(data);
    };
    if (partner && patientData) {
      getStatesForPartner();
    }
  }, [partner, patientData]);

  const createPasswordSection = (
    <OnboardingFormCreatePassword
      setNextStep={() => setStep(5)}
      partner={partner}
      {...ComponentsProps}
    />
  );

  const referralSection = (
    <OnboardingFormSectionReferral
      setNextStep={() => setStep(6)}
      partner={partner}
      {...ComponentsProps}
    />
  );

  const medicalPersonalSection = (
    <OnboardingFormSectionMedicalPersonal
      setNextStep={() => setStep(7)}
      partner={partner}
      coupon={coupon}
      {...ComponentsProps}
    />
  );

  /*
    to avoid rendering steps with insufficient data, we need to do this check here
    partner is set (only after step 0), but partnerData hasn't loaded yet
    return early and wait for it to load
  */
  if (partner && !partnerData && step !== 0) {
    return <></>;
  }

  switch (step) {
    default:
      return (
        <OnboardingFormSectionRegister
          setStep={setStep}
          setNextStep={() => setStep(1)}
          loginMessage={loginMessage}
          formData={formData}
          setFormData={setFormData}
          step={step}
          partner={partner}
          setLocalGenPw={setLocalGenPw}
        />
      );
    case 1:
      return (
        <OnboardingFormSectionPersonalData
          setNextStep={() => setStep(2)}
          partner={partner}
          {...ComponentsProps}
        />
      );

    case 2:
      return (
        <OnboardingFormSelectionCondition
          setNextStep={() => setStep(3)}
          partner={partner}
          {...ComponentsProps}
        />
      );
    case 3:
      return (
        <OnboardingFormSectionAddress
          setNextStep={() => setStep(4)}
          partner={partner}
          {...ComponentsProps}
        />
      );
    case 4:
      if (!defaultPasswordUpdated) {
        return createPasswordSection;
      }
      if (partnerData?.onboardingRequireAttribution) {
        return referralSection;
      }
      return medicalPersonalSection;
    case 5:
      if (partnerData?.onboardingRequireAttribution) {
        return referralSection;
      }
      return medicalPersonalSection;
    case 6:
      return medicalPersonalSection;
    case 7:
      history.push("/thankyou");
      return <></>;
    case 8:
      history.push(state?.redirect || "/home");
      return <></>;
  }
};
