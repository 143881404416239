import styles from "../Profile.module.scss";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";

import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import { IUserForm } from "../Profile";

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { ReactComponent as CompleteMarkIcon } from "../../../assets/icons/complete-mark.svg";
import RequiredStarIcon from "../../../assets/icons/required-star.png";

export const MyForms = ({ title, list }: IUserForm) => {
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  return (
    <div className={styles.form}>
      <h3 className={styles.groupTitle}>{title}</h3>
      {list.map((form) => (
        <div
          onClick={() => window.open(form.url, "_blank")}
          key={form.title}
          className={styles.formItem}
        >
          <div className={styles.formItemContent}>
            <p className={styles.title}>{form.title}</p>
            {form.required && !form.completed && (
              <>
                {isResponsiveView ? (
                  <img
                    className={styles.requiredStarIcon}
                    src={RequiredStarIcon}
                    alt={form.title}
                  />
                ) : (
                  <div className={cn(styles.badge, styles.required)}>Required</div>
                )}
              </>
            )}
            {form.completed && (
              <>
                {isResponsiveView ? (
                  <CompleteMarkIcon />
                ) : (
                  <div className={cn(styles.badge, styles.completed)}>Completed</div>
                )}
              </>
            )}
          </div>
          <ArrowIcon className={styles.arrow} />
        </div>
      ))}
    </div>
  );
};
