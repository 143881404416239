import styles from "./VisitNotes.module.scss";
import {
  getBookingURI,
  bookingPatientType,
  bookingProviderType,
} from "../../helpers/getBookingURI";
import { ReactComponent as Calendar } from "../../assets/images/svg/calendar-date.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { useCheckScrollPositionIsBottom } from "../../hooks/useCheckScrollPositionIsBottom";
import Button from "../Button/Button";

interface IVisitNote extends bookingProviderType {
  provider: string;
  date: string;
  image: string;
  stack: string;
  noteContent: string;
}

interface IVisitNotes {
  handleOpenUpgradeModal: () => void;
  visitNote: IVisitNote;
  patientData: bookingPatientType;
}

export default function VisitNotes({
  handleOpenUpgradeModal,
  visitNote,
  patientData,
}: IVisitNotes) {
  const { acuityAppointmentLink, date, image, noteContent, provider, stack } = visitNote;

  const { scrollPositionBottom, scrollIsBottom } = useCheckScrollPositionIsBottom();

  const day = new Date(date).toDateString().split(" ");

  const time = new Date(date).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
    timeZone: "UTC",
  });

  const bookAgain = (provider: bookingProviderType, patient: bookingPatientType) => {
    if (patient.isOnMaintenanceMode) {
      handleOpenUpgradeModal();
      return;
    }
    const bookingUri = getBookingURI(provider, patient);
    window.open(bookingUri, "_blank");
  };
  return (
    <div className={styles.notes}>
      <div className={styles.notesTitle}>Notes</div>
      <div className={styles.notesContainer}>
        <div className={styles.notesItem}>
          <img className={styles.notesItemImage} src={image} alt={image} />
          <div>
            <span className={styles.notesItemText}>
              {provider.substr(0, provider.indexOf(","))}
              <div className={styles.notesItemGrayText}>{stack}</div>
            </span>
          </div>
        </div>
        <div className={styles.notesItem}>
          <Calendar className={styles.notesItemIcon} />
          <div>
            <span className={styles.notesItemText}>{day[0]}</span>
            <span className={styles.notesItemText}>&#09;{day[2]}</span>
            <span className={styles.notesItemText}>&#09;{day[1]}</span>
            <br />
            <div className={styles.notesItemGrayText}>{time}</div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.wrapperContent} ${
          scrollIsBottom && styles.wrapperContentWithoutShadow
        }`}
      >
        <div className={styles.wrapperContentNotes} onScroll={scrollPositionBottom}>
          {noteContent.split("\n").map((noteData: any) => (
            <div>{noteData}</div>
          ))}
        </div>
      </div>
      <Button
        className={styles.bookAgainBtn}
        onClick={() => bookAgain({ acuityAppointmentLink }, patientData)}
        title="Book again"
        icon={patientData?.isOnMaintenanceMode && <LockIcon />}
        tag="button"
      />
    </div>
  );
}
