import { EXCLUDED_FROM_TRACKING } from "../utils/emailDomains";

export const isNotExcludedFromTracking = (email: string, tracking: () => any) => {
  const emailDomain = email?.split("@")[1];

  if (EXCLUDED_FROM_TRACKING.includes(emailDomain)) {
    return false;
  }
  tracking();
};
