import { useState } from "react";
import FirebaseApi from "../api/FirebaseApi/FirebaseApi";
import { IPatient } from "../types/patient";
import { IRequestRefill } from "../api/FirebaseFunctions/FirebaseFunctions";

export const useRequestRefill = (patientData: IPatient) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const functions = new FirebaseApi().firebaseFunctions();

  const requestRefill = (data: Omit<IRequestRefill, "patientID">) => {
    setIsLoading(true);
    functions
      .requestRefill({
        ...data,
        patientID: patientData.uid,
      })
      .then((res) => {
        if (res.data.success) setIsSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { requestRefill, isLoading, isSuccess, setIsSuccess };
};
