import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import OnboardingFormSectionLogin from "./OnboardingFormSectionLogin/OnboardingFormSectionLogin";
import { IFormData, ICoupon } from "./OnboardingFormSection.interface";

import { B2bFlow } from "./OnboardingFlows/B2bFlow";
import { B2cFlow } from "./OnboardingFlows/B2cFlow";
import { partnerList } from "../../utils/partnerList";

export interface IOnboardingForm {
  step: number;
  setStep: (e: IOnboardingForm["step"]) => void;
  uid?: null | string;
  userEmail: string;
  pathname?: string;
  patientData: any;
  setLoginMessage: (msg: string) => void;
  loginMessage: string;
  setLoading: (loading: boolean) => void;
  partner?: any;
}

const OnboardingForm = (props: IOnboardingForm) => {
  const {
    uid,
    pathname,
    partner,
    loginMessage,
    patientData,
    setLoading,
    setLoginMessage,
    setStep,
    step,
    userEmail,
  } = props;

  const [formData, setFormData] = useState<IFormData | undefined>(undefined);
  const [rewardfulRef, setRewardfulRef] = useState(null);
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [localGenPw, setLocalGenPw] = useState<string | null>(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    //@ts-ignore
    if (window.rewardful) {
      //@ts-ignore
      window.rewardful("ready", () => {
        //@ts-ignore
        if (window.Rewardful.referral) {
          //@ts-ignore
          setRewardfulRef(window.Rewardful.referral);
        }
        //@ts-ignore
        if (window.Rewardful.coupon) {
          //@ts-ignore
          setCoupon(window.Rewardful.coupon);
        }
      });
    }
  }, []);

  useEffect(() => {
    const notFoundPartner = partnerList.find((p) => p.partner === partner) === undefined;
    if (notFoundPartner) {
      location.search = "";
      history.push(location);
    }
  }, [partner]);

  if (pathname === "/login") {
    return <OnboardingFormSectionLogin formData={formData} setFormData={setFormData} />;
  }

  const OnboardingProps = {
    uid,
    patientData,
    userEmail,
    formData,
    setFormData,
    step,
    setStep,
    coupon,
    setLoading,
    loginMessage,
    setLoginMessage,
    localGenPw,
    setLocalGenPw,
  };

  // This is a tricky little piece.
  // There are two ways we can get a "partner name" here.
  // 1. We are on an onboarding URL that ends with ?pname=PARTNER_NAME. If this is the case, then "partner" is set because
  //    it is passed in from OnboardingView that picks it out of the URL.
  // 2. A patient started onboarding on a URL ending with ?pname=PARTNER_NAME, then they abandoned before finishing. However,
  //    we saved a partnerName to their Firestore doc. So now, when they come back to the site to finish onboarding, they will
  //    log in. At that point, we load the patientData where a partnerName is set. This tells us that this patient needs to be put
  //    back into the B2B sign uo flow.
  // Thus, check if 1 or 2 is true and if that is the case, send them to B2B.
  const partnerName = partner ?? patientData?.partnerName;
  if (partnerName)
    return <B2bFlow setFirstStep={() => setStep(0)} partner={partnerName} {...OnboardingProps} />;

  return (
    <B2cFlow setFirstStep={() => setStep(0)} rewardfulRef={rewardfulRef} {...OnboardingProps} />
  );
};

export default OnboardingForm;
