import { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { ReactComponent as IconSpinner } from "../../../../assets/icons/spinner.svg";
import FirebaseApi from "../../../../api/FirebaseApi/FirebaseApi";
import { IOnboardingForm } from "../../OnboardingForm";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";
import Text from "../../../Text/Text";

import styles from "./OnboardingFormDiscountCode.module.scss";
import { ICoupon } from "../../OnboardingFormSection.interface";

interface IOnboardingFormDiscountCode {
  label: string;
  formData?: any;
  uid?: IOnboardingForm["uid"];
  coupon?: ICoupon;
}

const OnboardingFormDiscountCode = (props: IOnboardingFormDiscountCode) => {
  const { label, formData, uid, coupon } = props;
  const [value, setValue] = useState(coupon ? "Discount Applied" : "");
  const [loading, setLoading] = useState(false);
  const [isReferralDiscount, setIsReferralDiscount] = useState(false);
  const [status, setStatus] = useState<"default" | "success" | "failure">(
    coupon ? "success" : "default"
  );

  const [msg, setMsg] = useState(coupon ? coupon.name : "");

  const [focus, setFocus] = useState(!!value);

  const [patientData, setPatientData]: [any, any] = useState();

  const getData = useCallback(
    async function () {
      try {
        if (formData) {
          const { email, personalData } = formData;
          setPatientData({ email, ...personalData });
        }

        if (uid) {
          const firestore = new FirebaseApi().firebaseFirestore();
          const data = await firestore.getPatientData(uid);
          setPatientData(data);
        }
      } catch (error) {}
    },
    [formData, uid]
  );

  useEffect(() => {
    if (!patientData) {
      getData();
    }
  }, [getData, patientData]);

  const handleClick = async () => {
    const functions = new FirebaseApi().firebaseFunctions();
    if (patientData && uid) {
      setLoading(true);

      const promotionCodeResponse = await functions.applyPromotionCode({
        patientID: uid,
        subscriptionID: patientData.subscriptionID,
        isTest: patientData.isTestCustomer,
        code: value,
      });

      setLoading(false);

      if (promotionCodeResponse.success) {
        setStatus("success");
        setMsg(promotionCodeResponse.userExplanation);
        return;
      }

      setStatus("failure");
      setMsg("Invalid discount code");
    }
  };

  useEffect(() => {
    if (coupon?.id) {
      setStatus("success");
      setMsg(
        "$15 off will be applied when your first full membership payment is processed, 7 days after your trial begins."
      );
      setIsReferralDiscount(true);
    }

    return () => setIsReferralDiscount(false);
  }, [coupon?.id]);

  return (
    <>
      <label
        className={cn(
          styles.element,
          focus && styles["isFocused"],
          status === "failure" && styles["isAlert"],
          status === "success" && styles["isValid"]
        )}
      >
        <span className={styles.label}>{isReferralDiscount ? "Referral Discount" : label}</span>
        {loading || status === "success" ? (
          <span className={styles.input}>{value}</span>
        ) : (
          <input
            className={styles.input}
            type="text"
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={(e) => {
              if (e.currentTarget.value === "") {
                setFocus(false);
                setStatus("default");
              }
            }}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              if (status === "failure") {
                setStatus("default");
              }
            }}
          />
        )}
        <span className={styles.tool}>
          {loading ? (
            <IconSpinner className={styles.spinner} />
          ) : (
            <button
              className={cn(
                styles.button,
                status !== "default" && styles["isDisabled"],
                status === "success" && styles["isValid"]
              )}
              onClick={handleClick}
              disabled={status !== "default"}
            >
              {status === "success" ? "APPLIED" : "APPLY"}
            </button>
          )}
        </span>
      </label>

      {status === "success" && msg && (
        <Text variant="medium" color="green" className={cn(styles.sucessText)}>
          {msg}
        </Text>
      )}

      {status === "failure" && !!msg && (
        <OnboardingFormError className="mt-small" errors={{ discount: msg }} inputName="discount" />
      )}
    </>
  );
};

export default OnboardingFormDiscountCode;
