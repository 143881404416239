import React, { useEffect, useState } from "react";
import styles from "./OnboardingFormStepper.module.scss";
import OnboardingFormStepperBack, { IOnboardingFormStepperBack } from "./OnboardingFormStepperBack";
import cn from "classnames";

interface IOnboardingFormStepper {
  step: number;
  length: number;
  backAction: IOnboardingFormStepperBack["backAction"];
  isResponsiveView: boolean;
  pathname?: string;
  upperStepper?: boolean;
}

const OnboardingFormStepper = (props: IOnboardingFormStepper) => {
  const { step, length, backAction, isResponsiveView, upperStepper } = props;

  const [progress, setProgress] = useState(((step + 1) / length) * 100);

  useEffect(() => {
    setProgress((step / length) * 100);
  }, [step, length]);

  return (
    <div className={cn("d-flex align-items-center", styles.element)}>
      {!isResponsiveView && step !== 0 && step !== 1 && step < 5 && (
        <OnboardingFormStepperBack backAction={backAction} />
      )}

      <div
        className={cn(styles.bar, {
          [styles["isDisabled"]]: step === 1,
          [styles.marginLeftForStepper]: (step === 1 || step === 5) && !upperStepper,
        })}
      >
        <div className={styles.progress} style={{ width: `${progress}%` }} />
        {/* <div className={styles.label}>{`Step ${step} of ${length}`}</div> */}
      </div>
    </div>
  );
};
export default OnboardingFormStepper;
