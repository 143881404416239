import React from "react";
import cn from "classnames";
import styles from "./OnboardingFormStepper.module.scss";
import { ReactComponent as IconBack } from "../../../../assets/icons/back.svg";

export interface IOnboardingFormStepperBack {
  backAction: () => void;
  className?: string;
}

const OnboardingFormStepperBack = ({ backAction, className }: IOnboardingFormStepperBack) => {
  return (
    <button
      className={cn(styles.back, className, "d-flex align-items-center justify-content-center")}
      onClick={backAction}
    >
      <IconBack />
    </button>
  );
};
export default OnboardingFormStepperBack;
