import qs from "qs";
import { partnersName } from "../utils/partnerList";
import { useLocation } from "react-router";

export const useGATrackingForSpecificPartner = () => {
  const { search } = useLocation();

  const queryCompany = qs.parse(search, { ignoreQueryPrefix: true });
  const pname =
    partnersName.hasOwnProperty(`${queryCompany?.pname}`.toUpperCase()) && queryCompany.pname;

  const runTracking = (partner: string, event: string) => {
    if (pname === partner) {
      // @ts-ignore
      window?.dataLayer?.push({ event });
    }
  };

  return {
    runTracking,
  };
};
