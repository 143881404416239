import React from "react";
import styles from "./OnboardingFormPaymentInput.module.scss";
import cn from "classnames";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";

interface IOnboardingFormPaymentInput {
  label: string;
  component: any;
  inputName: string;
  errors: any;
}

const OnboardingFormPaymentInput = (props: IOnboardingFormPaymentInput) => {
  const { label, component, inputName, errors } = props;

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.element)}>
        <span className={styles.label}>{label}</span>
        {component}
      </div>

      {errors[inputName] !== "" && errors[inputName] !== "initial" ? (
        <OnboardingFormError inputName={inputName} errors={errors} />
      ) : null}
    </div>
  );
};

export default OnboardingFormPaymentInput;
