export const getNavMenu = () => {
  return [
    {
      title: "Why Neura",
      to: "/why-neura",
      event: "nav_bar_why_neura_click",
    },
    {
      title: "FAQ",
      to: "/frequently-asked-questions",
      event: "nav_bar_faq_click",
    },
    {
      title: "Pricing",
      to: "/pricing",
      event: "nav_bar_pricing_click",
    },
    {
      title: "Blog",
      to: "/blog",
      event: "nav_bar_blog_click",
    },
  ];
};
