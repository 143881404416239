import React, { ReactNode, ReactNodeArray } from "react";
import cn from "classnames";

import styles from "./Text.module.scss";

interface IText {
  className?: string;
  children?: string | ReactNode | ReactNodeArray;
  variant?: "small" | "medium" | "large" | "title" | "h1" | "h2" | "h3" | "h4";
  color?:
    | "primary"
    | "primaryLight"
    | "green"
    | "pinkLight"
    | "pink"
    | "pinkDarker"
    | "white"
    | "whiteDirty"
    | "alert";

  weight?: "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
  element?: "span" | "div";
}

const Text = ({
  className,
  children,
  color = "primary",
  variant = "medium",
  weight = "400",
  element = "div",
}: IText) => {
  if (element === "span") {
    return (
      <span className={cn(className, styles[variant], styles[color], styles[`w${weight}`])}>
        {children}
      </span>
    );
  }

  return (
    <div className={cn(className, styles[variant], styles[color], styles[`w${weight}`])}>
      {children}
    </div>
  );
};

export default Text;
