export const SPACE = "hazf20hj4d0d";
export const CONTENTFUL_TOKEN = "SpbxJvudMyQFvo2cMuG7BmANMGD1N_iD_rCaw1cLny0";

// Keep consistent with server side (see constants.ts)
export const CARETEAM_ROLE_SPECIALIST = 1;
export const CARETEAM_ROLE_NEUROLOGIST = 2;
export const CARETEAM_ROLE_PRIMARY_CARE = 3;
export const CARETEAM_ROLE_NURSE = 4;
export const CARETEAM_ROLE_CONCIERGE = 5;
export const CARETEAM_ROLE_COACH = 6;
