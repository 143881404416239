import { useRef, useEffect, useState } from "react";
import { ResourcesCard } from "../ResourcesCard/ResourcesCard";
import { ResourcesContentModal } from "../ResourceContentModal/ResourceContentMobile";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { ReactComponent as ArrowIcon } from "../../assets/icons/back.svg";
import styles from "./ResourcesList.module.scss";
import "swiper/css";
import firebase from "firebase/compat/app";
import { createClient } from "contentful";
import { ResourceContentModal } from "../ResourceContentModal/ResourceContentModal";
import Drawer from "react-modern-drawer";
import { ReactComponent as CloseIcon } from "../../assets/images/svg/cancel.svg";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import "swiper/css/navigation";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { conditions } from "../../utils/conditionFocus";
import cs from "classnames";

interface IProps {
  isResponsiveView: any;
}

export const ResourcesList = ({ isResponsiveView }: IProps) => {
  const [contentEntries, setContentEntries] = useState<any>(null);
  const [selectedResource, setSelectedResource] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  let firestore = firebase.firestore;
  const user = firebase.auth().currentUser;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: any) => `<strong>${text}</strong>`,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const url = node.data.target.fields.file.url;
        if (url.startsWith("//videos")) {
          // Causes rendering issues if not wrapped in a <p>
          return `<p><video src="https:${node.data.target.fields.file.url}" /></p>`;
        } else {
          return `<div class="${styles.wrapperImage}"><img class=${styles.image} src="https:${node.data.target.fields.file.url}" /></div>`;
        }
      },

      [INLINES.HYPERLINK]: (node: any) => {
        if (node.data.uri.includes("youtube.com/embed")) {
          return `<div class=${styles.wrapperVideo}><iframe title="Neura Health" src=${
            node.data.uri
          } allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" width="100%" height="${
            isResponsiveView ? "200" : "300"
          }" frameBorder="0" allowFullScreen></iframe></div>`;
        } else {
          return `<a class="${styles.link}" href=${node.data.uri}>${node.content[0].value}</a>`;
        }
      },
      [BLOCKS.LIST_ITEM]: (node: any) => {
        const liContent = documentToHtmlString(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => `${next(node.content)}`,
          },
        });

        return `<li class="${styles.contentListElement}"><p>${liContent}</p></li>`;
      },
      [BLOCKS.PARAGRAPH]: (node: any, next: any) =>
        `<p class="${styles.contentParagraph}">${next(node.content)}</p>`,
    },
  };

  const loadContentfulData = () => {
    const now = Date.now();
    firestore()
      .collection("patients")
      .doc(user?.uid)
      .get()
      .then((patientDoc) => {
        const activationTime = patientDoc.get("activationTime");
        if (activationTime === null) {
          return;
        }
        const joinTime = new Date(activationTime * 1000);

        const dayMs = 24 * 60 * 60 * 1000;
        const daysSinceJoined = Math.ceil((now - Number(joinTime)) / dayMs);
        let tagQueryArray = [];

        const patientData = patientDoc.data();

        if (patientData?.conditionFocus?.includes(conditions.HEADACHE_MIGRAINE)) {
          const queryStr = `day${daysSinceJoined}`;
          tagQueryArray.push(queryStr);
        }
        if (patientData?.conditionFocus?.includes(conditions.SLEEP)) {
          const queryStr = `sleepDay${daysSinceJoined}`;
          tagQueryArray.push(queryStr);
        }
        if (patientData?.conditionFocus?.includes(conditions.EPILEPSY)) {
          tagQueryArray.push(`epilepsyDay${daysSinceJoined}`);
        }
        if (
          patientData?.conditionFocus?.includes(conditions.CONCUSSION_TBI) ||
          patientData?.conditionFocus?.includes(conditions.STROKE) ||
          patientData?.conditionFocus?.includes(conditions.TREMOR) ||
          patientData?.conditionFocus?.includes(conditions.OTHER_UNDIAGNOSED)
        ) {
          tagQueryArray.push(`generalDay${daysSinceJoined}`);
        }
        const tagQuery = {
          "metadata.tags.sys.id[in]": tagQueryArray.join(","),
        };
        firestore()
          .collection("gezunt_keys")
          .doc("contentful")
          .get()
          .then((documentSnapshot) => {
            const contentfulDataDoc = documentSnapshot.data();
            const client = createClient({
              space: contentfulDataDoc?.space,
              accessToken: contentfulDataDoc?.token,
            });
            client
              .getEntries(tagQuery)
              .then((entries: any) => {
                const priority: any = {
                  day: 0,
                  sleepDay: 1,
                  epilepsyDay: 2,
                  generalDay: 3,
                };

                entries.items.forEach((entry: any) => {
                  const parsedTags = entry.metadata.tags
                    .map((tag: any) => {
                      const result = tag.sys.id.match(
                        /^(?<category>day||sleepDay||epilepsyDay||generalDay)(?<dayNum>\d+)$/
                      );
                      return {
                        dayNum: parseInt(result?.groups.dayNum),
                        priority: priority[result?.groups.category],
                      };
                    })
                    .filter((tag: any) => !isNaN(tag.dayNum) && !isNaN(tag.priority));
                  const minDayNum = Math.min(...parsedTags.map((tag: any) => tag.dayNum));
                  const topPriority = Math.min(...parsedTags.map((tag: any) => tag.priority));
                  entry["sortingKeys"] = {
                    minDayNum: minDayNum,
                    topPriority: topPriority,
                  };
                });

                entries.items.sort((a: any, b: any) =>
                  a.sortingKeys.topPriority === b.sortingKeys.topPriority
                    ? a.sortingKeys.minDayNum - b.sortingKeys.minDayNum
                    : a.sortingKeys.topPriority - b.sortingKeys.topPriority
                );
                setContentEntries(entries);
              })
              .catch((err: any) => console.log(err));
          });
      });
  };

  const handleClick = (title: string) => {
    setIsOpen(true);
    setSelectedResource(title);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  useEffect(() => {
    loadContentfulData();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  if (!contentEntries?.items || contentEntries?.items?.length === 0) return <></>;

  return (
    <div className={styles.root}>
      <div className={styles.topHeading}>
        <h3 className={styles.heading}>Resources for you</h3>
        <div className={styles.topHeading__navigation}>
          <div className={cs("prev", styles.topHeading__navigation__arrow)} ref={navigationPrevRef}>
            <ArrowIcon />
          </div>
          <div
            className={cs(
              "next",
              styles.topHeading__navigation__arrow,
              styles.topHeading__navigation__arrowNext
            )}
            ref={navigationNextRef}
          >
            <ArrowIcon />
          </div>
        </div>
      </div>
      <Swiper
        modules={[Navigation]}
        spaceBetween={18}
        slidesPerView={"auto"}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
          disabledClass: styles.topHeading__navigation__arrow__disabled,
        }}
        className={styles.mySwiper}
      >
        {contentEntries?.items.map((item: any) => {
          return (
            <SwiperSlide className={styles.swiperSlide}>
              <ResourcesCard
                handleClick={handleClick}
                image={"https:" + item.fields.heroImage.fields.file.url}
                description={item.fields.body}
                title={item.fields.title}
                authorName={item.fields.authorName}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {isOpen && isResponsiveView && (
        <Drawer
          open={true}
          onClose={() => setIsOpen(false)}
          direction="bottom"
          size="90%"
          style={{
            borderTopRightRadius: "30px",
            borderTopLeftRadius: "30px",
            overflow: "hidden",
          }}
        >
          <>
            <div className={styles.wrapperCloseButton}>
              <div className={styles.closeButton} onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </div>
            </div>
            <ResourcesContentModal
              handleClick={handleClick}
              title={selectedResource.title}
              image={selectedResource.image}
              description={selectedResource.description}
              authorName={selectedResource.authorName}
              options={options}
            />
          </>
        </Drawer>
      )}

      {isOpen && !isResponsiveView && (
        <ResourceContentModal
          handleClose={handleClose}
          title={selectedResource.title}
          image={selectedResource.image}
          description={selectedResource.description}
          authorName={selectedResource.authorName}
          options={options}
        />
      )}
    </div>
  );
};
