import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import amplitude from "amplitude-js";

import { GridElem } from "../../Grid/Grid";
import DescriptionInfo from "../../DescriptionInfo/DescriptionInfo";
import { PersonalDataForm } from "../OnboardingFormComponents/PersonalDataForm/PersonalDataForm";

import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { partnersName } from "../../../utils/partnerList";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import { FormValues } from "../OnboardingFormComponents/PersonalDataForm/PersonalDataForm";

import Leaf from "../../../assets/images/svg/leafs.svg";
import { ReactComponent as BackgroundIcon } from "../../../assets/images/svg/onboarding-personal-data-background-icon.svg";
import { ReactComponent as IconLeft } from "../../../assets/images/svg/onboarding-personal-data-iconLeft2.svg";

import styles from "./OnboardingFormSectionPersonalData.module.scss";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";

const OnboardingFormSectionPersonalData = (props: IOnboardingFormSection) => {
  const { runTracking } = useGATrackingForSpecificPartner();

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["lg"]}px)`,
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const handleTracking = (values: FormValues) => {
    amplitude.getInstance().logEvent("onboarding_v2_name_dob_click", {
      firstName: values.firstName,
      lastName: values.lastName,
    });

    isNotExcludedFromTracking(props.formData?.email || props.userEmail, () => {
      // @ts-ignore
      window?.dataLayer.push({
        event: "onboarding_v2_name_dob_click",
      });
    });

    if (props.partner) {
      // This event is for one specific partner, Theranica
      runTracking(partnersName.THERANICA, "neura_name-dob");
    }
  };

  return (
    <PersonalDataForm handleTracking={handleTracking} isPersonalDataStep {...props}>
      <GridElem
        className={cn({
          [styles.infoBox]: !smallScreen,
          [styles.infoBoxMobile]: smallScreen,
        })}
      >
        <DescriptionInfo
          className={!smallScreen && styles.descriptionText}
          smallScreen={smallScreen}
          text={
            <>
              Our neurologists have <br /> extensive experience <br /> in treating many <br />{" "}
              neurological conditions
            </>
          }
        />
      </GridElem>

      <IconLeft
        className={cn({
          [styles.iconLeft]: !smallScreen,
          [styles.iconLeftMobile]: smallScreen,
        })}
      />

      <BackgroundIcon className={styles.backgroundIcon} />

      {!isResponsiveView && <img className={styles.leafIcon} src={Leaf} alt="" />}
    </PersonalDataForm>
  );
};

export default OnboardingFormSectionPersonalData;
