import React, { RefObject, SelectHTMLAttributes, useEffect, useState } from "react";
import styles from "./Select.module.scss";

interface ISelect extends SelectHTMLAttributes<HTMLSelectElement> {
  options: IOption[];
  ref: RefObject<HTMLSelectElement>;
  onSelected: (value: string) => any;
  selectedProvider: string;
  name: string;
}
interface IOption {
  value: string;
  label: string;
}

export const Select = React.forwardRef<HTMLSelectElement, ISelect>(
  ({ name, options, selectedProvider, onSelected }, ref) => {
    const [value, setValue] = useState(selectedProvider);
    const [isExpanded, setExpanded] = useState(false);

    const displayValue = options.find((option) => value === option.value)?.label;

    const handleSelect = (event: React.MouseEvent<HTMLOptionElement>, option: IOption) => {
      event.stopPropagation();
      setValue(option.value);
      setExpanded(false);
    };

    useEffect(() => {
      if (selectedProvider) {
        setValue(selectedProvider);
      }
    }, [selectedProvider]);

    useEffect(() => {
      onSelected(value);
    }, [value]);

    return (
      <div className={styles.wrapper}>
        <select className={styles.hiddenSelect} ref={ref} name={name} value={value}>
          <option value="" />
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div>
          <div
            className={styles.inputContainer}
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            {displayValue}
          </div>
        </div>
        {isExpanded && (
          <div className={styles.wrapperOptions}>
            {options.map((option) => (
              <div
                className={styles.option}
                key={option.label}
                onClick={(event: any) => handleSelect(event, option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);
