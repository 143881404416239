import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY
    ? process.env.REACT_APP_FIREBASE_API_KEY
    : "AIzaSyBcrq14xZc_8bjUw_DStGUAeHBbJ4AdoQc",
  authDomain: "gezunt-c3bfe.firebaseapp.com",
  databaseURL: "https://gezunt-c3bfe.firebaseio.com",
  projectId: "gezunt-c3bfe",
  storageBucket: "gezunt-c3bfe.appspot.com",
  messagingSenderId: "643613380480",
  appId: "1:643613380480:web:cd7ca840ddeedc175dbb65",
  measurementId: "G-VCN5WHNL2T",
  hjid: 2502549,
  hjsv: 6,
  stripeApiKey:
    process.env.REACT_APP_ENV === "production"
      ? "pk_live_51I8qDlJVxwKiT5QKoVt0j3QE1UNkYa7HmedwpbZLvFX8jawxL2VnFQ564ipWJ6Ks6qqLJ2RRz8X7LhSVayyT0RN400uoLQ5smZ"
      : "pk_test_51I8qDlJVxwKiT5QKS7QE6RDECGAWYzqWNM8btWHPmXKgrquDjQTClsOTaGv67UfNd1bMYF0CRoBJuxoAmOBHobXk00i8oBSyYL",
};

export const firebaseApp = firebase.initializeApp(config);
export const firestore = getFirestore(firebaseApp);
