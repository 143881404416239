import React, { FC, ReactFragment } from "react";
import cn from "classnames";

import styles from "./DescriptionInfo.module.scss";

interface DescriptionInfoProps {
  text: string | ReactFragment;
  smallScreen?: boolean;
  className?: any;
}

const DescriptionInfo: FC<DescriptionInfoProps> = ({ text, smallScreen, className }) => {
  return <p className={cn(smallScreen ? styles.textMobile : styles.text, className)}>{text}</p>;
};

export default DescriptionInfo;
