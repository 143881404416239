import React from "react";
import styles from "./PastVisitCard.module.scss";
import { ReactComponent as Notes } from "../../assets/images/svg/notes.svg";

export default function PastVisitCard(props: any) {
  const { doctor, date, openVisitNotes } = props;

  const day = new Date(date).toDateString().split(" ");

  return (
    <div className={styles.doctor}>
      <div className={styles.doctorLeft}>
        <div className={styles.doctorDate}>
          <div className={styles.doctorDateTop}>{day[0]}</div>
          <div className={styles.doctorDateMiddle}>{day[2]}</div>
          <div className={styles.doctorDateBottom}>{day[1]}</div>
        </div>
        <div className={styles.doctorInfo}>
          <div className={styles.doctorInfoTop}>
            {doctor && doctor.careTeamMemberImageURL && (
              <img
                src={doctor.careTeamMemberImageURL}
                alt={doctor.name}
                className={styles.doctorImage}
              />
            )}
            <div className={styles.doctorInfoBlock}>
              <div className={styles.doctorName}>
                {doctor?.name?.substr(0, doctor.name.indexOf(","))}
              </div>
              <div className={styles.doctorStack}>{doctor && doctor.title}</div>
            </div>
          </div>
          <div className={styles.doctorInfoBottom}>
            <button className={styles.doctorNotes} onClick={openVisitNotes}>
              <Notes />
              <div className={styles.doctorNotesText}>Notes</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
