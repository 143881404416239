import React, { ReactNode } from "react";
import cn from "classnames";
import styles from "./OnboardingFormDescription.module.scss";

interface IOnboardingFormDescription {
  text: string | ReactNode;
  className?: string;
}

const OnboardingFormDescription = ({ text, className }: IOnboardingFormDescription) => (
  <h3 className={cn(styles.element, "text-center", className)}>{text}</h3>
);

export default OnboardingFormDescription;
