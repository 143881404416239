import React, { useEffect, useState } from "react";
import { FieldInputProps, FormikProps } from "formik";
import styles from "./OnboardingFormTextInput.module.scss";
import cn from "classnames";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";
import { ReactComponent as IconEye } from "../../../../assets/icons/eye.svg";

interface IOnboardingFormTextInput {
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  label: string;
  placeholder?: string;
  type?: "text" | "email" | "password" | "date";
  see?: boolean;
}

const OnboardingFormTextInput = (props: IOnboardingFormTextInput) => {
  const { field, form, label, placeholder, type = "text", see } = props;

  const [isType, setType] = useState(type);

  const [focus, setFocus] = useState(!!field.value);

  useEffect(() => {
    if (field.value !== "") {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <div
          className={cn(
            styles.element,
            focus && styles["isFocused"],
            field.value !== "" && !form.errors[field.name] && styles["isValid"],
            form.touched[field.name] && form.errors[field.name] && styles["isAlert"],
            placeholder && styles["hasPlaceholder"]
          )}
        >
          <label className={styles.label} htmlFor={label}>
            {label}
          </label>

          <input
            type={isType}
            id={label}
            className={styles.input}
            {...field}
            onFocus={() => setFocus(true)}
            onBlur={(e) => {
              field.onBlur(e);
              field.value === "" && setFocus(false);
            }}
            placeholder={placeholder}
            data-testid="onboardingFormTextInput"
          />

          {see && (
            <IconEye
              onClick={() => {
                isType === "password" ? setType("text") : setType("password");
              }}
              className={styles.eye}
            />
          )}
        </div>
      </div>

      {form.touched[field.name] && form.errors[field.name] && (
        <OnboardingFormError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};

export default OnboardingFormTextInput;
