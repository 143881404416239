import Button from "../Button/Button";
import styles from "./RecommendedCard.module.scss";
import firebase from "firebase/compat/app";
import { IProvider } from "../../types/provider";
import { IPatient } from "../../types/patient";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getBookingURI } from "../../helpers/getBookingURI";

interface IProps {
  patientID: string;
  recommendedVisit: IRecommendedVisit;
}

interface IRecommendedVisit {
  recommendedDate: string;
  recommendedProvider: string;
}

interface IDoctor extends IProvider {
  providerImage: string;
}

export const RecommendedCard = ({ patientID, recommendedVisit }: IProps) => {
  const [doctor, setDoctor] = useState<IDoctor | null>(null);
  const [patientData, setPatientData] = useState<IPatient | null>(null);
  const [recommendedDate, setRecommendedDate] = useState("");

  let firestore = firebase.firestore;

  const fetchPatient = async () => {
    if (patientID) {
      firestore()
        .collection("patients")
        .doc(patientID)
        .onSnapshot(async (docSnapshot: any) => {
          if (!docSnapshot) {
            return;
          }
          const data = docSnapshot.data();

          setPatientData(data);
        });
    }
  };

  const fetchProvider = () => {
    firestore()
      .collection("care_team")
      .doc(recommendedVisit?.recommendedProvider)
      .get()
      .then(async (querySnapshot) => {
        const data = querySnapshot.data() as IProvider;

        const providerImage = await firebase
          .storage()
          .refFromURL("gs://gezunt-c3bfe.appspot.com/doctors/" + data.imageFileName)
          .getDownloadURL();

        setDoctor({ ...data, providerImage });
      });
  };

  const bookAppointment = () => {
    if (!doctor || !patientData) return;

    const { acuityAppointmentLink } = doctor;
    const { firstName, lastName, email, phoneNumber } = patientData;

    const URI = getBookingURI(
      { acuityAppointmentLink },
      { firstName, lastName, email, phoneNumber }
    );

    window.open(URI, "_blank");
  };

  useEffect(() => {
    fetchPatient();
  }, []);

  useEffect(() => {
    if (!patientID) return;
    fetchProvider();
  }, [recommendedVisit]);

  const toLocalTime = () => {
    const time = new Date(recommendedVisit.recommendedDate);
    const timeZone = "America/New_York";
    const timeInNewYork = utcToZonedTime(time, timeZone);
    setRecommendedDate(`${timeInNewYork}`);
  };

  useEffect(() => {
    toLocalTime();
  }, [recommendedVisit.recommendedDate]);

  return (
    <div className={styles.doctor}>
      <div className={styles.doctorRecommendedHeader}>RECOMMENDED</div>
      <div className={styles.doctorTop}>
        <div className={styles.doctorDate}>
          Follow up visit by{" "}
          {recommendedDate && format(new Date(`${recommendedDate}`), "MMM d, yyyy")}
        </div>
      </div>
      <div className={styles.doctorBook}></div>
      <div className={styles.doctorInfo}>
        <div className={styles.doctorInfoContent}>
          <div className={styles.doctorInfoContentLeft}>
            <img src={doctor?.providerImage} alt={""} className={styles.doctorImage} />
            <div className={styles.doctorInfoBlock}>
              <div className={styles.doctorName}>{doctor?.name}</div>
              <div className={styles.doctorStack}>{doctor?.title}</div>
            </div>
          </div>
          <Button onClick={bookAppointment} className={styles.bookBtn} tag="button" title="Book" />
        </div>
      </div>
    </div>
  );
};
