import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { gtag, install } from "ga-gtag";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import styles from "./HomePage.module.scss";
import firebase from "firebase/compat/app";
import Navigation from "../../components/Navigation/Navigation";
import ModalLayout from "../../components/ModalLayout/ModalLayout";
import { UserPlan } from "../../components/UserPlan/UserPlan";
import BlogReader from "../../components/BlogReader/BlogReader";
import { CarePlanItemSubType, reverseItemSubType } from "../../utils/carePlantUtil";
import TrackHeadache from "../../components/TrackHeadache/TrackHeadache";
import { BookVisitModal } from "../../components/BookVisitModal/BookVisitModal";
import VisitNotes from "../../components/VisitNotes/VisitNotes";
import Loader from "../../components/Loader/Loader";
import Visits from "../../components/Visits/Visits";
import QRComponent from "../../components/QRComponent/QRComponent";
import bottomNavMenu from "../../helpers/bottomNavMenu";
import amplitude from "amplitude-js";
import Neurology from "../../assets/images/Neurology-Clinic.png";
import DownloadComponent from "../../components/DownloadComponent/DownloadComponent";
import ReferComponent from "../../components/ReferComponent/ReferComponent";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { UpgradePaymentsInfoModal } from "../../components/UpgradePaymentsInfoModal/UpgradePaymentsInfoModal";
import { partnersName } from "../../utils/partnerList";
import { UpdatePaymentModal } from "../../components/modals/UpdatePaymentModal/UpdatePaymentModal";
import { InsuranceInfoModal } from "../../components/modals/InsuranceInfoModal/InsuranceInfoModal";

type ModalVariant =
  | keyof typeof CarePlanItemSubType
  | "UPDATE_PAYMENT"
  | "VISIT_NOTES"
  | "INSURANCE"
  | "PAST_VISIT"
  | "EXPAND_QR"
  | "FIRST_TIME"
  | "REFERRAL"
  | "VISIT"
  | "UPGRADE";

export interface DailyCardProps {
  type: number;
  contentID?: string | number;
  url?: string;
  description?: string;
  status: number;
  uniqueID: string;
}

function HomePage({ uid }: any) {
  const history = useHistory();

  const [currentModal, setCurrentModal] = useState<ModalVariant | undefined>();
  const [subItem, setSubItem] = useState<DailyCardProps | undefined>();

  const [paywallEnabled, setPaywallEnabled] = useState(false);
  const [updatePaymentUrl, setUpdatePaymentUrl] = useState("");
  const [pastVisit, setPastVisit] = useState<any>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);
  const [patientData, setPatientData] = useState<any>(null);
  const [partnerData, setPartnerData] = useState<any>();
  const [pastDueIgnoreTime, setPastDueIgnoreTime] = useState<any>(Date.now() - 1000 * 3600 * 24);

  const [loading, setLoading] = useState<boolean>(false);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  let firestore = firebase.firestore;

  const clickModalHandler = (
    type: number,
    contentID: string | number,
    url: string,
    description: string,
    status: number,
    uniqueID: string
  ) => {
    setCurrentModal(reverseItemSubType[type as keyof typeof reverseItemSubType]);
    setSubItem({
      type,
      ...(contentID && { contentID }),
      ...(url && { url }),
      ...(description && { description }),
      status,
      uniqueID,
    });
  };

  const openModal = (modal: ModalVariant) => {
    setSubItem(undefined);
    setCurrentModal(modal);
  };

  const portalsPixels = () => {
    const options = {
      debug: false,
    };
    // @ts-ignore
    ReactPixel.init("669817293553583", undefined, options);
    ReactPixel.pageView();
    ReactPixel.trackCustom("neura_book-visit-button-clicked");

    TiktokPixel.init("C2J59L4QTD93QKDL0NF0", undefined, options);
    TiktokPixel.pageView();
    // @ts-ignore
    TiktokPixel.track("neura_book-visit-button-clicked");

    install("AW-675319768", { send_page_view: false });
    gtag("event", "conversion", {
      send_to: "AW-675319768/X37QCL_Y28gZENifgsIC",
      value: 1.0,
      currency: "USD",
    });
  };

  const bookVisit = (isDisabled?: boolean, skipInsuranceCheck?: boolean) => {
    if (isDisabled) {
      openModal("UPGRADE");
      return;
    }
    if (patientData?.partnerName === partnersName.THERANICA) {
      // This event is for one specific partner, Theranica
      // @ts-ignore
      window?.dataLayer?.push({ event: "neura_book-visit-button-clicked" });
      portalsPixels();
    }

    setUpdatePaymentUrl("");
    if (
      !patientData?.innDetails &&
      partnerData?.showInsuranceUIBeforeBooking &&
      !skipInsuranceCheck
    ) {
      openModal("INSURANCE");
    } else {
      openModal("VISIT_NOTES");
    }
  };

  const openVisitNotes = (visitNote: any, careTeam: any) => {
    const provider = careTeam[visitNote.provider];
    setPastVisit({
      provider: provider.name,
      date: visitNote.date,
      image: provider.careTeamMemberImageURL,
      stack: provider.title,
      noteContent: visitNote.content,
      acuityAppointmentLink: provider.acuityAppointmentLink,
    });
    openModal("PAST_VISIT");
  };

  const updatePayment = (url: string) => {
    setUpdatePaymentUrl(url);
    openModal("UPDATE_PAYMENT");
  };

  const openStripePortal = async () => {
    const functions = new FirebaseApi().firebaseFunctions();

    window.location.href = await functions.createCustomerPortalSession({
      // @ts-ignore
      patientID: patientData.uid,
      isTest: !(process.env.REACT_APP_ENV === "production"),
    });
  };

  function handleClose(): void {
    setSubItem(undefined);
    setCurrentModal(undefined);
    setUpdatePaymentUrl("");
    setPastVisit(null);
  }

  function logOut() {
    amplitude.getInstance().setUserId(null);

    // @ts-ignore
    Kustomer.logout((_callbackResponse, _error) => {
      // @ts-ignore
      Kustomer.stop();
    });

    firebase.auth().signOut();
    history.push({
      pathname: "/login",
      search: window.location.search,
    });
    window.location.reload();
  }

  const bookVisitComponent = (
    <BookVisitModal
      uid={uid}
      patientData={patientData}
      partnerData={partnerData}
      updatePayment={updatePayment}
    />
  );
  const insuranceComponent = (
    <InsuranceInfoModal
      patientID={uid}
      partnerData={partnerData}
      handleClose={() => bookVisit(false, true)}
    />
  );

  const blogComponent =
    patientData && subItem ? <BlogReader subItem={subItem} patientData={patientData} /> : undefined;

  const modals: Record<ModalVariant, React.ReactNode> = {
    CONTENT_MEDICATION: blogComponent,
    CARE_TEAM: undefined,
    BOOK_APPOINTMENT:
      !patientData?.innDetails && partnerData?.showInsuranceUIBeforeBooking
        ? insuranceComponent
        : bookVisitComponent,
    COACHING_CHECKIN: undefined,
    CONTENT_EXERCISE: blogComponent,
    BOOK_COACHING: bookVisitComponent,
    CONTENT_GENERIC: blogComponent,
    TRACK_HEADACHES: <TrackHeadache />,
    FOOD: blogComponent,
    GENERIC_URL: undefined,
    UPDATE_PAYMENT: (
      <UpdatePaymentModal url={updatePaymentUrl} handleClose={() => bookVisit(false, true)} />
    ),
    VISIT_NOTES: bookVisitComponent,
    INSURANCE: insuranceComponent,
    PAST_VISIT: patientData && (
      <VisitNotes
        handleOpenUpgradeModal={() => openModal("UPGRADE")}
        visitNote={pastVisit}
        patientData={{
          firstName: patientData.firstName,
          lastName: patientData.lastName,
          email: patientData.email,
          phoneNumber: patientData.phoneNumber,
          isOnMaintenanceMode: patientData.isOnMaintenanceMode,
        }}
      />
    ),
    EXPAND_QR: <QRComponent />,
    FIRST_TIME: <DownloadComponent isResponsiveView={isResponsiveView} />,
    REFERRAL: patientData && <ReferComponent referralLink={patientData.affiliateLink} />,
    VISIT: (
      <Visits
        uid={uid}
        isOnMaintenanceMode={patientData?.isOnMaintenanceMode}
        bookVisit={bookVisit}
        openVisitNotes={openVisitNotes}
      />
    ),
    UPGRADE: <UpgradePaymentsInfoModal handleClose={handleClose} />,
  };

  const renderBottomNavMenu = bottomNavMenu.map((el) => (
    <li key={el.title}>
      <a href={`https://www.neurahealth.co${el.to}`} rel="noreferrer" target="_blank">
        {el.title}
      </a>
    </li>
  ));

  useEffect(() => {
    document.body.classList.toggle("no-chat", true);
  }, [isResponsiveView]);

  useEffect(() => {
    if (patientData && patientData.kustomerID) {
      // @ts-ignore
      Kustomer.start({ brandId: "61b91090a1a78441a08b05a8" }, function () {
        const functions = new FirebaseApi().firebaseFunctions();
        functions.getJWTToken(uid).then(async (jwtToken) => {
          // @ts-ignore
          await Kustomer.login({ jwtToken: jwtToken });
        });
      });
    }
  }, [patientData, uid]);

  useEffect((): any => {
    setLoading(true);
    let subscription: Promise<void> = firestore()
      .collection("launch_controls")
      .doc("paywall")
      .get()
      .then((docSnapshot) => {
        setLoading(false);

        if (!docSnapshot) {
          return;
        }
        const paywallData: any = docSnapshot.data();
        setPaywallEnabled(paywallData.enabled);
      });
    return () => subscription;
  }, [firestore]);

  useEffect((): any => {
    let subscription: any;

    if (uid) {
      setLoading(true);

      subscription = firestore()
        .collection("patients")
        .doc(uid)
        .onSnapshot(async (docSnapshot: any) => {
          if (!docSnapshot) {
            setLoading(false);
            return;
          }

          const data = docSnapshot.data();

          await setSubscriptionStatus(docSnapshot.get("subscriptionStatus"));

          setPatientData(data);

          if (data?.activationTime) {
            const difference = Date.now() / 1000 - data?.activationTime;
            const isFirstLogin = localStorage.getItem("isFirstLogin");

            if (difference <= 24 * 3600 && !isFirstLogin) {
              // update: QR modal should not be visible on first login
              // openModal("FIRST_TIME");
              localStorage.setItem("isFirstLogin", "logged in");
            }
          }
          setLoading(false);
        });
    }

    return () => subscription;
  }, [firestore, uid]);

  useEffect(() => {
    const body: any = document.querySelector("body");

    body.style.overflow = currentModal || subItem ? "hidden" : "auto";

    return () => {
      body.style.overflow = "auto";
    };
  }, [currentModal, subItem]);

  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    const getPartnerData = async () => {
      const data = await firestore.getPartnerData(patientData?.partnerName || "d2c");
      setPartnerData(data);
    };
    getPartnerData();
  }, [patientData?.partnerName]);

  if (loading) return <Loader />;

  if (paywallEnabled) {
    const validStatuses = ["active", "trialing", "past_due"];

    if (subscriptionStatus && !validStatuses.includes(subscriptionStatus)) {
      return (
        <div className={styles.modal}>
          <div className={styles.modalWrap}>
            <img
              style={{ height: 230, width: 360, marginBottom: 10 }}
              src={Neurology}
              alt={"Neurology"}
            />
            <div className={styles.modalText}>
              There was a problem with your subscription. Please check your payment method and
              update it if needed.
            </div>

            <div className={styles.modalText}>
              Contact support@neurahealth.co if you have questions.
            </div>

            <button className={styles.modalButton} onClick={openStripePortal}>
              Payment Settings
            </button>

            <button className={styles.modalButtonSecondary} onClick={logOut}>
              Log Out
            </button>
          </div>
        </div>
      );
    }

    // If the status is past due, show a warning but let them dismiss
    // it for 24 hours / until next log out.
    if (subscriptionStatus === "past_due") {
      if (Date.now() - pastDueIgnoreTime > 1000 * 3600 * 24) {
        return (
          <div className={styles.modal}>
            <div className={styles.modalWrap}>
              <img style={{ height: 230, width: 360 }} src={Neurology} alt={"Neurology"} />
              <div className={styles.modalText}>
                We were not able to process payment on your card. Please visit our website to ensure
                that your payment details are valid.
              </div>

              <div className={styles.modalText}>
                Contact support@neurahealth.co if you have questions.
              </div>

              <button
                className={styles.modalButton}
                onClick={() => setPastDueIgnoreTime(Date.now())}
              >
                Dismiss
              </button>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <>
      <div className={styles.home}>
        <div className={styles.container}>
          <Navigation
            patientData={patientData}
            isResponsiveView={isResponsiveView}
            openVisitsMobile={() => openModal("VISIT")}
          />
          <div className={styles.content}>
            <UserPlan
              uid={uid}
              patientData={patientData}
              clickModalHandler={clickModalHandler}
              isResponsiveView={isResponsiveView}
              referModalHandler={() => openModal("REFERRAL")}
              bookVisit={bookVisit}
              openQRCode={() => openModal("EXPAND_QR")}
            />
            <Visits
              uid={uid}
              bookVisit={bookVisit}
              openVisitNotes={openVisitNotes}
              inVisible={true}
              isOnMaintenanceMode={patientData?.isOnMaintenanceMode}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <ul>{renderBottomNavMenu}</ul>
        </div>
        {currentModal ? (
          isResponsiveView ? (
            <Drawer
              open={!!currentModal}
              onClose={handleClose}
              direction="bottom"
              className={currentModal === "UPGRADE" ? styles.drawerInfo : styles.drawer}
            >
              <button className={styles.close} onClick={handleClose}>
                <Cancel />
              </button>
              {modals[currentModal]}
            </Drawer>
          ) : (
            <ModalLayout handleClose={handleClose} className={styles[currentModal]}>
              {modals[currentModal]}
            </ModalLayout>
          )
        ) : null}
      </div>
    </>
  );
}

export default HomePage;
