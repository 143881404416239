import { useState } from "react";

export const useValidatePassword = () => {
  const [passwordStatus, setPasswordStatus] = useState({
    charLength: false,
    upperLower: false,
    number: false,
  });

  const validatePassword = (value: any) => {
    setPasswordStatus({
      charLength: value.length >= 8,
      upperLower: value.match(/^(?=.*[a-z])(?=.*[A-Z])/),
      number: value.match(/^(?=.*[0-9])/),
    });
  };

  return { passwordStatus, validatePassword };
};
