import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { ResetPasswordForm } from "../../components/ResetPasswordForm/ResetPasswordForm";

export const WelcomeBackPage = () => {
  return (
    <OnboardingLayout visibleLogo>
      <ResetPasswordForm
        title="Welcome Back!"
        subTitle="Enter your email address below and we will send you a link to set your password"
        buttonLabel="Email Me a Link"
      />
    </OnboardingLayout>
  );
};
