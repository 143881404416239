import styles from "./VerifyEmailPage.module.scss";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { FeedbackMessageCard } from "../../components/FeedbackMessageCard/FeedbackMessageCard";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect, useState } from "react";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";

type queryType = { apiKey: string; lang: string; mode: string; oobCode: string };

export const VerifyEmailPage = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const params = useLocation();
  const query = qs.parse(params.search) as queryType;

  const verifyEmail = async () => {
    setLoading(true);
    const { oobCode, apiKey } = query;
    const URL = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=${apiKey}`;

    try {
      const response = await fetch(URL, {
        method: "POST",
        body: JSON.stringify({ oobCode }),
      });

      setLoading(false);
      setError(false);

      if (!response.ok) throw new Error("Something went wrong");
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const title = error ? "Error" : "Success";
  const subtitle = error ? "Could Not Verify Email" : "Email Verified";

  return (
    <OnboardingLayout visibleLogo>
      {loading ? (
        <div className={styles.loader}>
          <LoaderSpinner />
        </div>
      ) : (
        <FeedbackMessageCard
          title={title}
          subtitle={subtitle}
          buttonLabel="Login"
          redirect={() => history.push("/login")}
        />
      )}
    </OnboardingLayout>
  );
};
