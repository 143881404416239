import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import { Link, useHistory, useLocation } from "react-router-dom";
import OnboardingFormHeading from "../OnboardingForm/OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import Grid, { GridElem } from "../Grid/Grid";
import OnboardingFormTextInput from "../OnboardingForm/OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormDescription from "../OnboardingForm/OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import OnboardingFormSubmit from "../OnboardingForm/OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

import qs from "query-string";

import styles from "./ResetPasswordForm.module.scss";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { useMediaQuery } from "react-responsive";

type queryType = { email: string };

export const ResetPasswordForm = ({
  title,
  subTitle,
  buttonLabel,
}: {
  title: string;
  subTitle: string;
  buttonLabel: string;
}) => {
  const [openModal, setOpenModal] = useState(false);

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const history = useHistory();
  const params = useLocation();
  const query = qs.parse(params.search) as queryType;

  const handleCloseModal = () => {
    setOpenModal(false);
    history.push({ pathname: "/login", search: window.location.search });
  };

  const resetPassword = async ({ email }: { email: string }) => {
    const firebaseAuth = new FirebaseApi().auth();

    await firebaseAuth.resetPassword(email);

    setOpenModal(true);
  };

  return (
    <Formik
      initialValues={{ email: (query.email && decodeURIComponent(query.email)) ?? "" }}
      onSubmit={resetPassword}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Please enter a valid email.").required("Email is required."),
      })}
    >
      {({ isValid, isSubmitting, errors }) => {
        return (
          <Form
            className={cn("d-flex flex-column h-100", { [styles.minHeight]: smallScreen })}
            id="password_recovery"
          >
            <OnboardingFormHeading title={title} />

            <OnboardingFormDescription text={subTitle} />

            <Grid rowGap={0} className={styles.resetPassInput}>
              <GridElem>
                <Field
                  inputMode="search"
                  label="Enter an email"
                  name="email"
                  type="email"
                  component={OnboardingFormTextInput}
                />
              </GridElem>
            </Grid>

            <div className={styles.submitButton}>
              <OnboardingFormSubmit
                title={buttonLabel}
                disabled={!isValid}
                isSubmitting={isSubmitting}
              />
            </div>

            <OnboardingFormDescription
              className={styles.logInText}
              text={
                <span>
                  <Link
                    className={styles.link}
                    to={{ pathname: "/onboarding", search: window.location.search }}
                    onClick={(event) => isSubmitting && event.preventDefault()}
                  >
                    Sign up
                  </Link>{" "}
                  or{" "}
                  <Link
                    className={styles.link}
                    to={{ pathname: "/login", search: window.location.search }}
                    onClick={(event) => isSubmitting && event.preventDefault()}
                  >
                    Log in
                  </Link>
                </span>
              }
            />

            {openModal && (
              <Modal>
                <OnboardingFormDescription
                  className="mb-3"
                  text="Please check your email for a link to set your password"
                />

                <div className="d-flex justify-content-center">
                  <Button tag="button" title="OK" onClick={handleCloseModal} />
                </div>
              </Modal>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
