import { partnerList } from "../../../../utils/partnerList";
import styles from "./OnboardingFormHeader.module.scss";
import cs from "classnames";

interface IProps {
  partner?: string | null;
}

const OnboardingFormHeader = ({ partner }: IProps) => {
  if (partner)
    return (
      <>
        {partnerList
          .filter(({ partner: company }) => partner === company)
          .map(({ logo }, index) => (
            <div key={index} className="d-flex flex-column w-100">
              <div className={styles.logo}>{logo}</div>
              <p className={cs(styles.subHeader, styles.subHeader__center)}>
                Book your visit in just a few minutes. Enter your email to get started.
              </p>
            </div>
          ))}
      </>
    );

  return (
    <div className="d-flex flex-column w-100">
      <h4 className={styles.header}>
        Your First Week is <div>Just $1</div>
      </h4>

      <p className={styles.subHeader}>Create your account - cancel anytime</p>
    </div>
  );
};

export default OnboardingFormHeader;
