import { ResetPasswordForm } from "../../components/ResetPasswordForm/ResetPasswordForm";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";

export const ForgotPasswordPage = () => {
  return (
    <OnboardingLayout visibleLogo>
      <ResetPasswordForm
        title="Reset Password"
        subTitle="Enter your email address below and we'll send you a link to reset your password."
        buttonLabel="Reset Password"
      />
    </OnboardingLayout>
  );
};
