import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LogoDesktop } from "../../assets/images/svg/logo.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as Calendar } from "../../assets/images/svg/calendar-visit.svg";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";

import { Link } from "react-router-dom";
import styles from "./Navigation.module.scss";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import MenuDropDown from "../MenuDropDown/MenuDropDown";

import ModalLayout from "../../components/ModalLayout/ModalLayout";
import { useRequestRefill } from "../../hooks/useRequestRefill";
import { SuccessRequest } from "../RequestRefillForm/SuccessRequest";
import { RequestRefillForm } from "../RequestRefillForm/RequestRefillForm";
import Drawer from "react-modern-drawer";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

export default function Navigation(props: any) {
  const { patientData, openVisitsMobile } = props;

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const [expanded, setExpanded] = useState<boolean>(false);

  const { isLoading, isSuccess, setIsSuccess, requestRefill } = useRequestRefill(patientData);

  const node = useRef<any>(null);
  const openMenuHandler = () => {
    if (isResponsiveView) {
      // setIsOpen(true);
      setIsMobileMenu(true);
    }

    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(false);
    setIsMobileMenu(false);
  };

  const openPrescriptionModal = () => {
    setIsOpen(true);
    handleClose();
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (node.current && !node.current.contains(event.target)) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [node]);

  useEffect(() => {
    setIsSuccess(false);
  }, [isOpen]);

  return (
    <>
      <div className={styles.header}>
        <Link to="/home" className={styles.headerLogo}>
          <LogoDesktop />
        </Link>
        <div className={styles.headerButtonContainer}>
          <button onClick={openVisitsMobile} className={styles.headerVisitIcon}>
            <Calendar />
          </button>
          <button onClick={openMenuHandler} className={styles.headerBlock}>
            <div className={styles.headerText}>{patientData?.firstName.slice(0, 1)}</div>
            {!isResponsiveView && (
              <div className={styles.headerUser}>
                {patientData?.firstName}
                <Arrow className={styles.arrow} />
              </div>
            )}
          </button>
        </div>
        {expanded && !isResponsiveView && (
          <MenuDropDown
            openPrescriptionModal={openPrescriptionModal}
            isCalledFromTop={true}
            node={node}
            handleClose={handleClose}
            patientData={patientData}
          />
        )}
      </div>

      {isResponsiveView && isMobileMenu && (
        <Drawer
          open={isMobileMenu}
          onClose={handleClose}
          direction="bottom"
          className={classNames(styles.drawer, styles.drawerMobileMenu)}
        >
          <MenuDropDown
            patientData={patientData}
            handleClose={handleClose}
            openPrescriptionModal={openPrescriptionModal}
          />
        </Drawer>
      )}

      {isResponsiveView ? (
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          direction="bottom"
          size="80%"
          style={{
            padding: "32px",
          }}
        >
          {isSuccess ? (
            <SuccessRequest handleClick={() => setIsOpen(false)} />
          ) : (
            <>
              <button className={styles.close} onClick={() => setIsOpen(false)}>
                <Cancel />
              </button>

              <RequestRefillForm
                handleSubmitRequestRefill={(data) => requestRefill(data)}
                isLoading={isLoading}
              />
            </>
          )}
        </Drawer>
      ) : (
        <>
          {isOpen && (
            <ModalLayout
              handleClose={() => setIsOpen(false)}
              style={{ height: isSuccess ? "250px" : "500px" }}
            >
              {isSuccess ? (
                <SuccessRequest handleClick={() => setIsOpen(false)} />
              ) : (
                <RequestRefillForm
                  handleSubmitRequestRefill={(data) => requestRefill(data)}
                  isLoading={isLoading}
                />
              )}
            </ModalLayout>
          )}
        </>
      )}
    </>
  );
}
