interface IProps {
  patientPartner: string;
  partnerName: string;
  event: string;
}

export const runGAForSpecificPartner = ({ patientPartner, partnerName, event }: IProps) => {
  if (patientPartner === partnerName) {
    // @ts-ignore
    window?.dataLayer?.push({ event });
  }
};
