import React, { useEffect, useState } from "react";
import styles from "../../views/Profile/Profile.module.scss";
import cn from "classnames";

export default function Input({
  field,
  fullWidth,
  label,
  onChange,
  error,
  type = "text",
  className,
}: any) {
  const [focus, setFocus] = useState(!!field.value);

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.inputWrapper}>
        <div
          className={cn(
            styles.element,
            fullWidth && styles.fullWidth,
            focus && styles.isFocused,
            error && styles.error
          )}
        >
          <span className={styles.label}>{label}</span>

          <input
            type={type}
            className={styles.input}
            {...field}
            onFocus={() => setFocus(true)}
            onChange={onChange}
            onBlur={(e) => {
              !!!field.value && setFocus(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}
