import InstagramIcon from "../assets/icons/instagram-icon.svg";
import FacebookIcon from "../assets/icons/facebook-icon.svg";
import LinkedinIcon from "../assets/icons/linkedin-icon.svg";
import TwitterIcon from "../assets/icons/twitter-icon.svg";
import YoutubeIcon from "../assets/icons/youtube-icon.svg";
import FlagIcon from "../assets/icons/flag-icon.svg";

const footerIcons: FooterIconsT[] = [
  { img: InstagramIcon, path: "https://www.instagram.com/neurahealth" },
  { img: FacebookIcon, path: "https://www.facebook.com/neurahealth" },
  { img: LinkedinIcon, path: "" },
  { img: TwitterIcon, path: "http://www.twitter.com/neurahealthco" },
  { img: YoutubeIcon, path: "" },
  { img: FlagIcon, path: "" },
];

type FooterIconsT = {
  img: string;
  path: string;
};

export default footerIcons;
