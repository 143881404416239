import React, { useEffect, useRef, useState } from "react";
import styles from "./VisitCard.module.scss";
import { ReactComponent as Dots } from "../../assets/images/svg/three-dots.svg";
import { ReactComponent as Schedule } from "../../assets/images/svg/schedule.svg";
import { ReactComponent as Copy } from "../../assets/images/svg/copy.svg";

export default function VisitCard(props: any) {
  const { doctor, date, mtngUrl, zoomUrl } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [innerExpanded, setInnerExpanded] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const nodeParent = useRef<any>(null);
  const nodeChild = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (nodeParent.current && !nodeParent.current.contains(event.target)) {
        setExpanded(false);
      } else if (nodeChild.current && !nodeChild.current.contains(event.target)) {
        setInnerExpanded(false);
        setIsCopied(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [nodeParent, nodeChild]);

  const day = new Date(date).toDateString().split(" ");

  const dates: any = new Date(date);
  // So much work to get a 12 hour time...
  let hours = dates.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12;
  const minutes = "0" + dates.getMinutes();

  const dateStr = `${hours}:${minutes.substr(-2)}${ampm}`;

  const clickOpenChild = () => {
    setInnerExpanded(!innerExpanded);
    setExpanded(false);
  };

  const clickOpenParent = () => {
    if (!innerExpanded) {
      setExpanded(!expanded);
    }
  };

  const infoBlock: any = [
    {
      icon: <Schedule />,
      text: "Reschedule/ Cancel",
      onClick: () => {
        window.open(mtngUrl, "_blank");
      },
    },
    {
      icon: <Copy />,
      text: "Zoom Link",
      onClick: () => clickOpenChild(),
      options: [
        {
          icon: <Schedule />,
          text: "Open link",
          onClick: () => {
            window.open(zoomUrl, "_blank");
          },
        },
        {
          icon: <Copy />,
          text: isCopied ? "Copied" : "Copy link",
          onClick: async () => {
            await navigator.clipboard.writeText(zoomUrl);
            setIsCopied(true);
            // document.execCommand("copy", true, zoomUrl);
          },
        },
      ],
    },
  ];

  return (
    <div className={styles.doctor}>
      <div className={styles.doctorLeft}>
        <div className={styles.doctorDate}>
          <div className={styles.doctorDateTop}>{day[0]}</div>
          <div className={styles.doctorDateMiddle}>{day[2]}</div>
          <div className={styles.doctorDateBottom}>{day[1]}</div>
        </div>
        <div className={styles.doctorInfo}>
          <div className={styles.doctorInfoTop}>
            {doctor && doctor.careTeamMemberImageURL && (
              <img
                src={doctor.careTeamMemberImageURL}
                alt={doctor.name}
                className={styles.doctorImage}
              />
            )}
            <div className={styles.doctorInfoBlock}>
              <div className={styles.doctorName}>
                {doctor && doctor.name.substr(0, doctor.name.indexOf(","))}
              </div>
              <div className={styles.doctorStack}>{doctor && doctor.title}</div>
            </div>
          </div>
          <div className={styles.doctorInfoBottom}>At {dateStr}</div>
        </div>
      </div>

      <button onClick={clickOpenParent} className={styles.doctorRight}>
        <Dots />
      </button>
      {expanded && (
        <div className={styles.drop} ref={nodeParent}>
          {infoBlock.map(
            (
              item: {
                onClick: (appointment: any) => void;
                text: {} | null | undefined;
                icon:
                  | string
                  | number
                  | boolean
                  | {}
                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                  | React.ReactNodeArray
                  | React.ReactPortal
                  | null
                  | undefined;
              },
              index: any
            ) => {
              return (
                <div className={styles.dropItem} key={index} onClick={() => item.onClick(item)}>
                  {item.icon}
                  <div className={styles.dropText}>{item.text}</div>
                </div>
              );
            }
          )}
        </div>
      )}
      {innerExpanded && (
        <div className={styles.drop} ref={nodeChild}>
          {infoBlock[1].options.map((item: any) => (
            <div className={styles.dropItem} key={item.text} onClick={item.onClick}>
              {item.icon}
              <div className={styles.dropText}>{item.text}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
