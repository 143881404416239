import styles from "../CancelMembership.module.scss";
import amplitude from "amplitude-js";

interface IProps {
  feedbackValue?: string;
  setFeedback: (feedback: string) => void;
  handleSubmit: (wantsALaCarte: boolean) => void;
}

export const FeedbackStep = ({ feedbackValue, setFeedback, handleSubmit }: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setFeedback(value);
  };

  const onSubmit = () => {
    const isWantsALaCarte = false;
    handleSubmit(isWantsALaCarte);

    amplitude.getInstance().logEvent("cancelled_subscription_at_term_end");
  };

  return (
    <>
      <textarea
        placeholder="Type something here..."
        className={styles.textareaField}
        value={feedbackValue}
        onChange={handleChange}
      />
      <button onClick={onSubmit} className={styles.contentFormButton}>
        Cancel Membership
      </button>
    </>
  );
};
