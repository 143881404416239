import React from "react";
import ReactDOM from "react-dom";
import "../src/assets/styles/base.scss";
import App from "./App";
import { Router } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FirestoreProvider } from "@react-firebase/firestore";
import { config } from "./config";
import { createBrowserHistory } from "history";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { RefreshPlanItemCountProvider } from "./context/refreshPlanItemCount";
import { OnboardingStepCountProvider } from "./context/onboardingStepCount";
import { ReactQueryProvider } from "./context/ReactQueryProvider";

const history = createBrowserHistory();

ReactDOM.render(
  <ReactQueryProvider>
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <FirestoreProvider firebase={firebase} {...config}>
        <Router history={history}>
          <OnboardingStepCountProvider>
            <RefreshPlanItemCountProvider>
              <App />
            </RefreshPlanItemCountProvider>
          </OnboardingStepCountProvider>
        </Router>
      </FirestoreProvider>
    </FirebaseAuthProvider>
  </ReactQueryProvider>,
  document.getElementById("root")
);
