import React from "react";

export function hsScript() {
  return (
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/19952123.js"
    />
  );
}
