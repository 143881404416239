export const BottomSiteNavMenu = () => {
  return [
    {
      title: "Home",
      to: "/",
      event: "footer_home_click",
    },
    {
      title: "Start Today",
      to: "/onboarding",
      event: "footer_start_today_click",
    },
    {
      title: "Why Neura",
      to: "/why-neura",
      event: "footer_why_neura_click",
    },
    {
      title: "FAQ",
      to: "/frequently-asked-questions",
      event: "footer_faq_click",
    },
    {
      title: "Pricing",
      to: "/pricing",
      event: "footer_pricing_click",
    },
    {
      title: "Blog",
      to: "/blog",
      event: "footer_blog_click",
    },
  ];
};
