import React from "react";
import styles from "./OnboardingFormHeading.module.scss";
import cn from "classnames";

interface IOnboardingFormHeading {
  title: string;
  className?: string;
}

const OnboardingFormHeading = ({ title, className }: IOnboardingFormHeading) => (
  <h1 className={cn(styles.element, "text-center", className)}>{title}</h1>
);

export default OnboardingFormHeading;
