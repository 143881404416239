import Icon1 from "../assets/images/svg/onboardingMemberShip/onboarding-icon1.svg";
import Icon2 from "../assets/images/svg/onboardingMemberShip/onboarding-icon2.svg";
import Icon3 from "../assets/images/svg/onboardingMemberShip/onboarding-icon3.svg";
import Icon4 from "../assets/images/svg/onboardingMemberShip/onboarding-icon4.svg";

const ONBOARDING_MEMBERSHIP_LIST = [
  {
    name: "Same week neurologist video visits",
    image: Icon1,
  },
  {
    name: "Unlimited 24/7 in-app chat with your care team",
    image: Icon2,
  },
  {
    name: "Twice-monthly sessions with dedicated care coach",
    image: Icon3,
  },
  {
    name: "Personalized treatment plan including prescriptions",
    image: Icon4,
  },
];

export default ONBOARDING_MEMBERSHIP_LIST;
