import React from "react";
import cn from "classnames";
import styles from "./GoogleMarketButton.module.scss";
import { ReactComponent as GoogleMarket } from "../../assets/images/svg/google-play-logo.svg";

interface IGoogleMarketButton {
  black?: boolean;
  className?: string;
  onClick?: () => void;
}

const GoogleMarketButton = ({ black, className, onClick }: IGoogleMarketButton) => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.gezuntpatient"
      className={cn(
        styles.element,
        className,
        black && styles["isBlack"],
        "d-inline-flex justify-content-center align-items-center"
      )}
      onClick={onClick}
    >
      <GoogleMarket />
    </a>
  );
};

export default GoogleMarketButton;
