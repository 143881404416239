import styles from "./ResourcesCard.module.scss";

interface IProps {
  title: string;
  image: string;
  authorName: string;
  description: React.ReactNode;
  handleClick: (data: any) => void;
}

export const ResourcesCard = ({ title, image, description, authorName, handleClick }: IProps) => {
  const cutTitle = (title: string) => {
    const maxTitleLength = 70;
    if (title.length <= maxTitleLength) return title;

    title = title.slice(0, maxTitleLength);
    return `${title} ...`;
  };

  return (
    <div
      className={styles.root}
      onClick={() => handleClick({ title, image, description, authorName })}
    >
      <div className={styles.root__image} style={{ backgroundImage: `url(${image})` }} />
      <div className={styles.root__content}>
        <h3 className={styles.heading}>{cutTitle(title)}</h3>
      </div>
    </div>
  );
};
