import React from "react";
import styles from "./BookCard.module.scss";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import classNames from "classnames";
import { IProvider } from "../../types/provider";
import Button from "../Button/Button";
import { ReactComponent as IconSpin } from "../../assets/icons/spinner.svg";

interface IBookCard {
  currentLink?: IProvider["acuityAppointmentLink"];
  careTeamMember: IProvider;
  onClick: () => void;
}

export default function BookCard({ careTeamMember, onClick, currentLink }: IBookCard) {
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  return (
    <div className={styles.doctor}>
      <div className={styles.doctorBio}>
        <div className={styles.doctorImage}>
          <img
            src={careTeamMember.careTeamMemberImageURL}
            alt={careTeamMember.careTeamMemberImageURL}
          />
        </div>
        <div className={styles.doctorInfo}>
          <div>
            <div className={styles.doctorName}>
              {careTeamMember.name.substr(0, careTeamMember.name.indexOf(","))}
            </div>
            <div className={styles.doctorStack}>
              {careTeamMember.slotCount && careTeamMember.slotCount < 2
                ? careTeamMember.title
                : `${careTeamMember.slotCount} openings in the next two weeks`}
            </div>
            <Link
              to={`/doctor/${careTeamMember.careTeamMemberID}`}
              className={classNames(styles.about, styles.mobileLink)}
            >
              Read detailed bio
            </Link>
          </div>
          <Link
            to={`/doctor/${careTeamMember.careTeamMemberID}`}
            className={classNames(styles.about, isResponsiveView && styles.inVisible)}
          >
            Read detailed bio
          </Link>
        </div>
      </div>
      <div className={styles.doctorLeft}>
        <Button onClick={onClick} className={styles.bookButton} disabled={!!currentLink}>
          {currentLink === careTeamMember.acuityAppointmentLink ? <IconSpin /> : "Book"}
        </Button>
      </div>
    </div>
  );
}
