import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import OnboardingFormDescription from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import { SetNewPasswordForm } from "../../components/SetNewPasswordForm/SetNewPasswordForm";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import qs from "query-string";

export const SetNewPasswordPage = () => {
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  type queryType = { apiKey: string; lang: string; mode: string; oobCode: string };

  const history = useHistory();
  const params = useLocation();
  const query = qs.parse(params.search) as queryType;

  const handleCloseModal = () => {
    setOpenSuccessfulModal(false);
    history.push({ pathname: "/login" });
  };

  const resetPassword = async ({ password }: { password: string }) => {
    const { oobCode, apiKey } = query;
    const URL = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}`;
    try {
      const response = await fetch(URL, {
        method: "POST",
        body: JSON.stringify({ oobCode, newPassword: password }),
      });
      if (!response.ok) throw new Error("Something went wrong");

      setOpenSuccessfulModal(true);

      return response;
    } catch (error) {
      setOpenErrorModal(true);
      return error;
    }
  };

  return (
    <>
      <OnboardingLayout visibleLogo>
        <SetNewPasswordForm
          title="Set New Password"
          subTitle="Please enter your new password."
          onSubmit={resetPassword}
          visibleLinks
        />
      </OnboardingLayout>

      {openErrorModal && (
        <Modal>
          <OnboardingFormDescription
            className="mb-3"
            text="Could not update your password. Please try again"
          />
          <div className="d-flex justify-content-center">
            <Button tag="button" title="OK" onClick={() => setOpenErrorModal(false)} />
          </div>
        </Modal>
      )}

      {openSuccessfulModal && (
        <Modal>
          <OnboardingFormDescription
            data-testid="successfulMessage"
            className="mb-3"
            text="Password successfully updated"
          />
          <div className="d-flex justify-content-center">
            <Button tag="button" title="OK" onClick={handleCloseModal} />
          </div>
        </Modal>
      )}
    </>
  );
};
