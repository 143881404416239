import { useEffect, useState } from "react";
import styles from "../../views/HomePage/HomePage.module.scss";
import firebase from "firebase/compat/app";
import VisitCard from "../VisitCard/VisitCard";
import classNames from "classnames";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { ReactComponent as Empty } from "../../assets/images/svg/empty.svg";
import PastVisitCard from "../PastVisitCard/PastVisitCard";
import { RecommendedCard } from "../../components/RecommendedCard/RecommendedCard";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { Tabs, ITab } from "../Tabs/Tabs";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import Button from "../Button/Button";

interface IRecommendedVisit {
  recommendedDate: string;
  recommendedProvider: string;
  isOnMaintenanceMode?: boolean;
}

const tabs: ITab[] = [
  { title: "Upcoming", id: 1 },
  { title: "Past", id: 2 },
];

export default function Visits(props: any) {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [careTeam, setCareTeam] = useState<any>({});
  const [careTeamUpcoming, setCareTeamUpcoming] = useState<any>({});
  const [appointments, setAppointments] = useState<any>(null);
  const [visitNotes, setVisitNotes] = useState<any>(null);
  const [recommendedVisit, setRecommendedVisit] = useState<IRecommendedVisit | null>(null);

  const { uid, bookVisit, openVisitNotes } = props;

  let firestore = firebase.firestore;
  const functions = new FirebaseApi().firebaseFunctions();

  useEffect(() => {
    let subsciption: any;
    setLoading(true);
    if (uid) {
      subsciption = firebase
        .functions()
        .httpsCallable("getVisitNotesForPatient")({ patientID: uid })
        .then((response) => {
          const responseData = response.data;
          const ctmIDMap: any = {};
          for (const noteObj of responseData) {
            ctmIDMap[noteObj.provider] = 1;
          }
          const ctmIDs = Object.keys(ctmIDMap);
          if (ctmIDs.length > 0) {
            firestore()
              .collection("care_team")
              .where(firestore.FieldPath.documentId(), "in", ctmIDs)
              .get()
              .then((querySnapshot) => {
                let careTeamArray: any[] = [];
                querySnapshot.forEach((documentSnapshot) => {
                  careTeamArray.push({
                    ...documentSnapshot.data(),
                    ...{ careTeamMemberID: documentSnapshot.id },
                  });
                });

                const teamMemberImageURLPromises = careTeamArray.map((careTeamMember) =>
                  firebase
                    .storage()
                    .refFromURL(
                      "gs://gezunt-c3bfe.appspot.com/doctors/" + careTeamMember.imageFileName
                    )
                    .getDownloadURL()
                );
                Promise.all(teamMemberImageURLPromises).then((teamMemberImageURLs) => {
                  let finalTeam: any = {};
                  let teamMemberLen = teamMemberImageURLs.length;
                  for (let i = 0; i < teamMemberLen; i++) {
                    finalTeam[careTeamArray[i].careTeamMemberID] = {
                      ...careTeamArray[i],
                      ...{ careTeamMemberImageURL: teamMemberImageURLs[i] },
                    };
                  }
                  setCareTeam(finalTeam);
                });
                setLoading(false);
              });

            setVisitNotes(responseData);
            setLoading(false);
          }
        });
    }

    return () => subsciption;
  }, [firestore, uid]);

  useEffect(() => {
    let subsciption: any;
    if (uid) {
      setLoading(true);
      subsciption = firebase
        .functions()
        .httpsCallable("getUpcomingAppointmentsForPatient")({ patientID: uid })
        .then((response) => {
          const responseData = response.data;
          const ctmIDMap: any = {};
          for (const apptObj of responseData) {
            ctmIDMap[apptObj.provider] = 1;
          }
          const ctmIDs = Object.keys(ctmIDMap);
          if (ctmIDs.length > 0) {
            firestore()
              .collection("care_team")
              .where(firestore.FieldPath.documentId(), "in", ctmIDs)
              .get()
              .then((querySnapshot) => {
                let careTeamArray: any[] = [];
                querySnapshot.forEach((documentSnapshot) => {
                  careTeamArray.push({
                    ...documentSnapshot.data(),
                    ...{ careTeamMemberID: documentSnapshot.id },
                  });
                });

                const teamMemberImageURLPromises = careTeamArray.map((careTeamMember) =>
                  firebase
                    .storage()
                    .refFromURL(
                      "gs://gezunt-c3bfe.appspot.com/doctors/" + careTeamMember.imageFileName
                    )
                    .getDownloadURL()
                );
                Promise.all(teamMemberImageURLPromises).then((teamMemberImageURLs) => {
                  let finalTeam: any = {};
                  let teamMemberLen = teamMemberImageURLs.length;
                  for (let i = 0; i < teamMemberLen; i++) {
                    finalTeam[careTeamArray[i].careTeamMemberID] = {
                      ...careTeamArray[i],
                      ...{ careTeamMemberImageURL: teamMemberImageURLs[i] },
                    };
                  }
                  setCareTeamUpcoming(finalTeam);
                });
              });
          } else {
          }
          setAppointments(responseData);
          setLoading(false);
        });
    }

    return () => subsciption;
  }, [firestore, uid]);

  useEffect(() => {
    if (!uid) return;
    functions.fetchRecommendedVisits({ patientID: uid }).then((response) => {
      setRecommendedVisit(response);
    });
  }, []);

  return (
    <div className={classNames(styles.contentRight, props.inVisible && styles.inVisible)}>
      <div className={styles.panel}>
        <div className={styles.panelText}>Visits</div>
        <Button
          tag="button"
          icon={props?.isOnMaintenanceMode && <LockIcon />}
          onClick={() => bookVisit(!!props?.isOnMaintenanceMode)}
          className={styles.panelButton}
          title="Book a Visit"
        />
      </div>
      {(appointments || visitNotes) && !!careTeam ? (
        <div style={{ padding: "0 32px" }}>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
            <div className={styles.tabsContent}>
              {activeTab === 1 && appointments && appointments.length ? (
                appointments.map((item: any, index: number) => (
                  <VisitCard
                    key={`item-appointments${Math.random()}${index}`}
                    doctor={careTeamUpcoming[item.provider] && careTeamUpcoming[item.provider]}
                    contentID={item.provider}
                    mtngUrl={item.mgmtLink}
                    zoomUrl={item.zoomLink}
                    date={item.date}
                  />
                ))
              ) : activeTab === 1 ? (
                <div className={styles.empty}>
                  <Empty />
                  <div className={styles.emptyTitle}>You have no upcoming appointments</div>
                </div>
              ) : null}
              {activeTab === 1 && recommendedVisit && (
                <RecommendedCard patientID={uid} recommendedVisit={recommendedVisit} />
              )}
              {visitNotes && visitNotes.length && activeTab === 2 ? (
                visitNotes.map((item: any, index: number) => (
                  <PastVisitCard
                    key={`item-visits${index}`}
                    doctor={!!careTeam[item.provider] && careTeam[item.provider]}
                    contentID={item.provider}
                    openVisitNotes={() => openVisitNotes(item, careTeam)}
                    date={item.date}
                  />
                ))
              ) : activeTab === 2 ? (
                <div className={styles.empty}>
                  <Empty />
                  <div className={styles.emptyTitle}>
                    Visit notes will appear here after your appointment
                  </div>
                </div>
              ) : null}
            </div>
          </Tabs>
        </div>
      ) : (
        loading && (
          <div style={{ textAlign: "center" }}>
            <LoaderSpinner />
          </div>
        )
      )}
    </div>
  );
}
