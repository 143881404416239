import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { useMediaQuery } from "react-responsive";
import OnboardingForm, { IOnboardingForm } from "../../components/OnboardingForm/OnboardingForm";

import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import Modal from "../../components/Modal/Modal";
import OnboardingMemberShipModal from "../../components/OnboardingForm/OnboardingFormSectionMemberShip/OnboardingFormSectionMemberShip";
import qs from "qs";
import { partnersName } from "../../utils/partnerList";

import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import { IPatient } from "../../types/patient";

interface IOnboardingView {
  userEmail: IOnboardingForm["userEmail"];
  uid?: IOnboardingForm["uid"];
  providerId: null | string;
  userData?: any;
  pathname: string;
}

const OnboardingView = ({ userEmail, uid, providerId, userData }: IOnboardingView) => {
  const [step, setStep] = useState<IOnboardingForm["step"]>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [loginMessage, setLoginMessage] = useState<string>("Welcome back!");
  const [patientData, setPatientData] = useState<IPatient | null>(null);

  const history = useHistory();
  const location = useLocation();

  const queryCompany = qs.parse(location.search, { ignoreQueryPrefix: true });
  const partner = partnersName.hasOwnProperty(`${queryCompany?.pname}`.toUpperCase())
    ? queryCompany.pname
    : queryCompany?.pname;

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  useEffect(() => {
    if (location.pathname === "/onboarding" && step === 0) {
      setModalOpen(true);
    }
  }, [location.pathname, step]);

  const fetchUserData = (uid: string) => {
    amplitude.getInstance().setUserId(uid);

    const firebaseFirestore = new FirebaseApi().firebaseFirestore();

    firebaseFirestore.getPatientData(uid).then((data) => {
      setPatientData(data);

      setLoading(false);

      if (location.pathname === "/thankyou" && !data?.subscriptionStatus) {
        return history.push({
          pathname: "/home",
          search: window.location.search,
        });
      }
      setOnboardingStep(data);
    });
  };

  const setOnboardingStep = (data: IPatient) => {
    if (!data?.lastName) {
      return setStep(1);
    }

    if (
      data?.conditionFocus?.length === 0 ||
      data?.conditionFocus === undefined ||
      data?.conditionFocus === null
    )
      return setStep(1);

    if (!data?.phoneNumber) {
      return setStep(3);
    }
    if (!data?.defaultPasswordUpdated) {
      return setStep(4);
    }
    if (!data?.consentFormsSigned || !data?.planName) {
      return setStep(5);
    }

    if (!data?.subscriptionStatus) {
      if (partner) return setStep(5);
      return setStep(6);
    }

    return setStep(8);
  };

  useEffect(() => {
    setLoading(true);
    if (providerId) {
      if (location.pathname === "/thankyou") {
        !userEmail && history.push("/home");
      }

      if (["/home", "/user", "/forms"].includes(location.pathname)) {
        !userEmail &&
          history.push({
            pathname: "/login",
            state: { redirect: location.pathname },
          });
      }

      if (userEmail) {
        if (uid) {
          fetchUserData(uid);
        }
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId, userEmail, uid]);

  useEffect(() => {
    if (partner) {
      location.search = location.search.toLowerCase();
      history.push(location);
    }
  }, [partner]);

  const props = {
    uid,
    patientData,
    step,
    setStep,
    setLoading,
    partner,
    pathname: location.pathname,
  };

  return (
    <>
      <OnboardingLayout loading={loading} modalOpen={modalOpen} {...props}>
        <OnboardingForm
          setLoginMessage={setLoginMessage}
          loginMessage={loginMessage}
          userEmail={userEmail}
          {...props}
        />
      </OnboardingLayout>

      {!loading && modalOpen && !partner && step === 0 && location.pathname === "/onboarding" && (
        <Modal isBenefits smallScreen={smallScreen}>
          <OnboardingMemberShipModal
            setModalOpen={() => setModalOpen(false)}
            isModalOpen={modalOpen}
            isSmallScreen={smallScreen}
          />
        </Modal>
      )}
    </>
  );
};

export default OnboardingView;
