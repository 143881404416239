import React from "react";
import styles from "./Burger.module.scss";
import cn from "classnames";

interface IProps {
  onClick?: () => void;
}

const Burger = ({ onClick }: IProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.element, "d-flex flex-column justify-content-between")}
    >
      <span className={cn(styles.plank, "d-inline-flex")} />
      <span className={cn(styles.plank, "d-inline-flex")} />
      <span className={cn(styles.plank, "d-inline-flex")} />
    </button>
  );
};

export default Burger;
