import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import styles from "./Layout.module.scss";

interface ILayout {
  children: any;
}

const Layout = (props: ILayout) => {
  const { children } = props;

  return (
    <>
      <Header />

      <main className={styles.element}>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
