import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import styles from "./GetHelp.module.scss";

import Button from "../Button/Button";
import ModalLayout from "../ModalLayout/ModalLayout";
import { RequestRefillForm } from "../RequestRefillForm/RequestRefillForm";
import { SuccessRequest } from "../RequestRefillForm/SuccessRequest";

import { ReactComponent as BookVisitIcon } from "../../assets/icons/DailyCard/Book_Visit.svg";
import { ReactComponent as MedicinesIcon } from "../../assets/icons/DailyCard/medicines.svg";
import { ReactComponent as TrackIcon } from "../../assets/icons/DailyCard/Track.svg";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { useRequestRefill } from "../../hooks/useRequestRefill";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Drawer from "react-modern-drawer";
import { IPatient } from "../../types/patient";
import { conditions } from "../../utils/conditionFocus";

type Props = {
  patientData: IPatient;
  bookVisit: (isOnMaintenanceMode: boolean) => void;
  openQRCode: () => void;
};

export const GetHelp = ({ patientData, bookVisit, openQRCode }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isSuccess, setIsSuccess, requestRefill } = useRequestRefill(patientData);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  useEffect(() => {
    setIsSuccess(false);
  }, [isOpen]);

  return (
    <>
      <div className={styles.wrapper}>
        <h3 className={styles.heading}>Get Help</h3>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <BookVisitIcon />
            <h3 className={styles.heading}>Book Visit With Care Team Member</h3>
          </div>
          <Button
            className={styles.button}
            tag="button"
            icon={patientData?.isOnMaintenanceMode && <LockIcon />}
            title="Book a Visit"
            onClick={() => bookVisit(!!patientData?.isOnMaintenanceMode)}
          />
        </div>

        <div className={styles.card}>
          <div className={styles.card__left}>
            <MedicinesIcon />
            <h3 className={styles.heading}>Request Refill</h3>
          </div>
          <Button
            onClick={() => setIsOpen(true)}
            className={styles.button}
            tag="button"
            title="Submit Request"
          />
        </div>

        {patientData?.conditionFocus?.includes(conditions.HEADACHE_MIGRAINE) && (
          <div className={styles.card}>
            <div className={styles.card__left}>
              <TrackIcon />
              <h3 className={styles.heading}>Record Headache Attacks</h3>
            </div>
            <Button
              onClick={openQRCode}
              className={styles.button}
              tag="button"
              title="Log Headache"
            />
          </div>
        )}
      </div>
      {isResponsiveView ? (
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          direction="bottom"
          size="80%"
          style={{
            padding: "32px",
          }}
        >
          {isSuccess ? (
            <SuccessRequest handleClick={() => setIsOpen(false)} />
          ) : (
            <>
              <button className={styles.close} onClick={() => setIsOpen(false)}>
                <Cancel />
              </button>

              <RequestRefillForm
                handleSubmitRequestRefill={(data) => requestRefill(data)}
                isLoading={isLoading}
              />
            </>
          )}
        </Drawer>
      ) : (
        <>
          {isOpen && (
            <ModalLayout
              handleClose={() => setIsOpen(false)}
              style={{ height: isSuccess ? "250px" : "500px" }}
            >
              {isSuccess ? (
                <SuccessRequest handleClick={() => setIsOpen(false)} />
              ) : (
                <RequestRefillForm
                  handleSubmitRequestRefill={(data) => requestRefill(data)}
                  isLoading={isLoading}
                />
              )}
            </ModalLayout>
          )}
        </>
      )}
    </>
  );
};
