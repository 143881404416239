import styles from "./RequestRefillForm.module.scss";
import Button from "../Button/Button";

export const SuccessRequest = ({ handleClick }: { handleClick: () => void }) => (
  <div className={styles.success}>
    <h1 className={styles.heading}>
      Your refill request was sent. We will get back to you as soon as possible.
    </h1>
    <Button onClick={handleClick} className={styles.button} tag="button" title="OK" />
  </div>
);
