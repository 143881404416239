import React from "react";
import cn from "classnames";

import styles from "./StartTodayButton.module.scss";

interface IProps {
  type?: "button" | "reset" | "submit";
  className?: string;
  text?: string;
  fixedHeader?: boolean;
  onClick?: () => void;
  menuOpened?: boolean;
}

const StartTodayButton = ({
  type = "button",
  className,
  text,
  fixedHeader,
  onClick,
  menuOpened,
}: IProps) => (
  <button
    type={type}
    onClick={onClick}
    className={cn(className, styles.button, {
      [styles.onFixedHeader]: fixedHeader,
      [styles.menuOpened]: menuOpened,
    })}
  >
    {text}
  </button>
);
export default StartTodayButton;
