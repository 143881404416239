import { useState, useContext, createContext } from "react";

interface IProps {
  children: React.ReactNode;
}

interface IOnboardingStepContext {
  stepCount: number;
  setStepCount: (stepCount: number) => void;
}

const OnboardingStepCountContext = createContext<IOnboardingStepContext | null>(null);

export const OnboardingStepCountProvider = ({ children }: IProps) => {
  const [stepCount, setStepCount] = useState(5);

  return (
    <OnboardingStepCountContext.Provider value={{ stepCount, setStepCount }}>
      {children}
    </OnboardingStepCountContext.Provider>
  );
};

export const useOnboardingStepCountContext = () => {
  const context = useContext(OnboardingStepCountContext);

  if (!context) {
    throw new Error("Error from OnboardingStepCountProvider context api");
  }

  return context;
};
