import React from "react";
import styles from "./QRComponent.module.scss";
import QRCode from "../../assets/images/qr-code.png";

export default function QRComponent() {
  return (
    <div className={styles.container}>
      <img className={styles.qrcode} src={QRCode} alt="qrcode" />
      <div className={styles.text}>Scan with your phone camera to download</div>
    </div>
  );
}
