import { useState } from "react";
import FirebaseApi from "../api/FirebaseApi/FirebaseApi";

export const useDefaultPasswordUpdate = () => {
  const [defaultPasswordUpdated, setDefaultPasswordUpdated] = useState<boolean | null>(null);

  const firebaseFirestore = new FirebaseApi().firebaseFirestore();

  const fetchDefaultPasswordUpdate = (uid: string) => {
    firebaseFirestore.getPatientData(uid).then((data) => {
      setDefaultPasswordUpdated(!!data.defaultPasswordUpdated);
    });
  };

  return { fetchDefaultPasswordUpdate, defaultPasswordUpdated };
};
