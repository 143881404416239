import styles from "./RequestRefillForm.module.scss";
import { LoaderHorizontal } from "../LoaderHorizontal/LoaderHorizontal";
import * as Yup from "yup";
import Button from "../Button/Button";
import Input from "../Input/Input";
import { IRequestRefill } from "../../api/FirebaseFunctions/FirebaseFunctions";
import { Formik, Form, Field, FormikHelpers } from "formik";

interface IProps {
  isLoading: boolean;
  handleSubmitRequestRefill: (data: Omit<IRequestRefill, "patientID">) => void;
}

type IFormikValues = Omit<IRequestRefill, "patientID">;

interface IFormikTouched {
  medicationName?: boolean;
  pharmacyName?: boolean;
  pharmacyAddress?: boolean;
}

const initialValues: IFormikValues = {
  medicationName: "",
  pharmacyName: "",
  pharmacyAddress: "",
};

const validationSchemaObject = Yup.object().shape({
  medicationName: Yup.string().required(),
  pharmacyName: Yup.string().required(),
  pharmacyAddress: Yup.string().required(),
});

export const RequestRefillForm = ({ isLoading, handleSubmitRequestRefill }: IProps) => {
  const handleErrorValidation = (errors: Partial<IFormikValues>) => Object.keys(errors).length > 0;
  const handleTouchedValidation = (touched: IFormikTouched) =>
    Object.keys(touched).length === 3 && Object.values(touched).every((value) => value === true);

  const handleSubmit = (values: IFormikValues, { setSubmitting }: FormikHelpers<IFormikValues>) => {
    handleSubmitRequestRefill(values);
    setSubmitting(false);
  };

  return (
    <>
      <h2 className={styles.title}>Request Refill</h2>
      <p className={styles.subTitle}>Please give us 5 days notice</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="medicationName"
              className={styles.textField}
              label="Medication Name"
              component={Input}
              fullWidth
              value={values.medicationName}
              onChange={handleChange}
              error={!!errors.medicationName && touched.medicationName}
            />
            <Field
              name="pharmacyName"
              className={styles.textField}
              label="Pharmacy Name"
              component={Input}
              fullWidth
              error={!!errors.pharmacyName && touched.pharmacyName}
              onChange={handleChange}
              value={values.pharmacyName}
            />
            <Field
              name="pharmacyAddress"
              className={styles.textField}
              label="Pharmacy Address"
              component={Input}
              fullWidth
              onChange={handleChange}
              value={values.pharmacyAddress}
              error={!!errors.pharmacyAddress && touched.pharmacyAddress}
            />

            {handleErrorValidation(errors) && handleTouchedValidation(touched) && (
              <p className={styles.error}>
                Please complete all the fields. For the address, enter "none" for online pharmacies.
              </p>
            )}

            <Button
              type="submit"
              disabled={isSubmitting || isLoading}
              className={styles.submitButton}
              tag="button"
              title={isLoading ? <LoaderHorizontal /> : "Send Request"}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
