// @ts-ignore
import { gtag, install } from "ga-gtag";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import { Field, Form, Formik, FormikHelpers } from "formik";
import amplitude from "amplitude-js";
import styles from "./OnboardingFormSectionRegister.module.scss";
import cn from "classnames";
import { levenshteinEditDistance } from "levenshtein-edit-distance";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import Grid, { GridElem } from "../../Grid/Grid";
import NumberOfStep from "../../NumberOfStep/NumberOfStep";
import OnboardingFormTextInput from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import OnboardingFormHeader from "../OnboardingFormComponents/OnboardingFormHeader/OnboardingFormHeader";

import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import { createHubspotUser } from "../../../helpers/hubspotCreateUserFormRequest";
import { generateUserPassword } from "../../../helpers/generatorPassword";
import { emailDomains } from "../../../utils/emailDomains";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";

import { ReactComponent as LoginIconBackground } from "../../../assets/icons/onboarding-login-background-icon.svg";
import { ReactComponent as IconHeader } from "../../../assets/icons/onboarding-login-header.svg";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import { RegisterSchema } from "../../../schemas/authForm";
import { useEffect, useRef } from "react";
import { partnersName } from "../../../utils/partnerList";

interface IOnboardingFormSection2 extends IOnboardingFormSection {
  setStep: any;
  setFormData: any;
  formData: any;
  setLocalGenPw: any;
}

type FormValuesT = { email: string; password: string };

const OnboardingFormSectionRegister = ({
  formData,
  setFormData,
  step,
  partner,
  setLocalGenPw,
}: IOnboardingFormSection2) => {
  const history = useHistory();
  const formikRef = useRef<null>(null);

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const { runTracking } = useGATrackingForSpecificPartner();

  const validateEmail = (value: string) => {
    let error;
    const v1Split = value.split("@");
    const v1 = v1Split[v1Split.length - 1] ?? "";

    if (
      value.includes("+") &&
      !value.endsWith(`@${emailDomains.NEURAHEALTH}`) &&
      !value.endsWith(`@${emailDomains.HTD}`)
    ) {
      // Only Neura employees can use + to make multiple accounts
      // Eg: sameer+1@neurahealth.co, sameer+2@neurahealth.co etc.
      // Make sure no one else outside the company can do this.
      error = "Emails cannot contain a + sign";
    } else if (value.endsWith(".vom") || value.endsWith(".con")) {
      error = "Did you mean .com?";
    } else if (
      levenshteinEditDistance(v1, "gmail.com") === 1 &&
      v1 !== "mail.com" &&
      v1 !== "ymail.com"
    ) {
      error = "Did you mean gmail.com?";
    } else if (levenshteinEditDistance(v1, "hotmail.com") === 1) {
      error = "Did you mean hotmail.com?";
    } else if (levenshteinEditDistance(v1, "yahoomail.com") === 1) {
      error = "Did you mean yahoomail.com?";
    } else if (levenshteinEditDistance(v1, "aol.com") === 1 && v1 !== "aon.com") {
      error = "Did you mean aol.com?";
    } else if (levenshteinEditDistance(v1, "icloud.com") === 1) {
      error = "Did you mean icloud.com?";
    } else if (
      value.endsWith(`${emailDomains.NEURAHEALTH}`) &&
      value.startsWith("liz") &&
      !value.includes("+") &&
      value !== "liz@neurahealth.co"
    ) {
      error = "Liz, please put a + in the email you use to create a test account.";
    }

    return error;
  };

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["lg"]}px)`,
  });

  const runPortalsPixel = () => {
    const options = {
      debug: false,
    };
    // @ts-ignore
    ReactPixel.init("669817293553583", undefined, options);
    ReactPixel.pageView();
    ReactPixel.trackCustom("neura_new-account-signup");

    TiktokPixel.init("C2J59L4QTD93QKDL0NF0", undefined, options);
    TiktokPixel.pageView();
    // @ts-ignore
    TiktokPixel.track("neura_new-account-signup");

    install("AW-675319768", { send_page_view: false });
    gtag("event", "conversion", {
      send_to: "AW-675319768/JWgECLzY28gZENifgsIC",
      value: 1.0,
      currency: "USD",
    });
  };

  const signUp = async (values: FormValuesT, formikHelpers: FormikHelpers<FormValuesT>) => {
    const functions = new FirebaseApi().firebaseFunctions();
    try {
      const firebaseAuth = new FirebaseApi().auth();

      const { user } = await firebaseAuth.signUp(values.email, values.password);

      if (user) {
        setFormData({
          ...formData,
          uid: user?.uid,
          email: values?.email,
        });

        setLocalGenPw(values.password);
        const firestore = new FirebaseApi().firebaseFirestore();

        if (partner) {
          firestore.createPatientWithPartner({
            uid: user?.uid,
            email: values?.email,
            partnerName: partner,
          });
        } else {
          firestore.createPatient({ uid: user?.uid, email: values?.email });
        }

        // This event is for one specific partner, Theranica
        runTracking(partnersName.THERANICA, "neura_new-account-signup");

        isNotExcludedFromTracking(values.email, () => {
          // @ts-ignore
          window?.dataLayer?.push({
            user_id: user?.uid,
          });

          // @ts-ignore
          window?.dataLayer?.push({
            event: "onboarding_v2_new_account_signup_click",
          });
        });

        if (partner === partnersName.THERANICA) {
          runPortalsPixel();
        }

        process.env.REACT_APP_ENV === "production" && ReactPixel.track("Lead");

        firebaseAuth.verifyEmail();

        const sourcePropName = partner ? "b2b_patients" : "lead_source";
        const sourcePropValue = partner ? "B2B_Patients" : "Consumers";
        await createHubspotUser(values?.email, user?.uid, sourcePropName, sourcePropValue);

        // Hubspot is being annoying and has decided to *sometimes* drop the
        // extra property I'm setting above. Just set it again here.
        functions.updateHubspotContact(sourcePropName, {
          email: values?.email,
          eventValue: sourcePropValue,
        });
        functions.updateHubspotContact("default_password_updated", {
          email: values.email,
          eventValue: false,
        });
        if (partner) {
          functions.updateHubspotContact("partner_name", {
            email: values.email,
            eventValue: partner,
          });
        }

        amplitude.getInstance().setUserId(user.uid);
        const emailIdentify = new amplitude.Identify().set("email", values?.email);
        amplitude.getInstance().identify(emailIdentify);
        amplitude.getInstance().logEvent("onboarding_v2_new_account_signup_click");

        if (window.hasOwnProperty("ReactNativeWebView")) {
          // @ts-ignore
          window?.ReactNativeWebView.postMessage(JSON.stringify({ eventType: "newSignup" }));
        }
      }
    } catch (error) {
      // @ts-ignore
      if (error.code === "auth/email-already-in-use") {
        const landingPage = await functions.loadPatientLandingPage(values.email);
        formikHelpers.setSubmitting(false);
        if (landingPage === "reset_pw") {
          history.push(`/welcome-back?email=${encodeURIComponent(values.email)}`);
        } else {
          history.push(`/login?email=${encodeURIComponent(values.email)}`);
        }
      } else {
        formikHelpers.setSubmitting(false);
        // @ts-ignore
        formikHelpers.setFieldError("email", error.message);
      }
    }
  };

  useEffect(() => {
    const generatedPassword = generateUserPassword();
    if (formikRef.current) {
      // @ts-ignore
      formikRef.current.setFieldValue("password", generatedPassword, true);
    }
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={signUp}
      validationSchema={RegisterSchema}
      validateOnChange
      validateOnMount
    >
      {(formikHelpers) => {
        return (
          <Form className="d-flex flex-column h-100" id="email_password_signup">
            {!isResponsiveView && <LoginIconBackground className={styles.loginBackgroundIcon} />}

            <div
              className={cn(styles.headerWrapper, "d-flex")}
              // style={{ width: partner ? "100%" : "180px" }}
            >
              <OnboardingFormHeader partner={partner} />
              {smallScreen && !partner && <IconHeader className={styles.iconHeader} />}
              {!smallScreen && <IconHeader className={styles.iconHeader} />}
            </div>

            <Grid rowGap={0} className={styles.wrapperField}>
              <GridElem>
                <Field
                  inputMode="email"
                  label="Enter your email"
                  name="email"
                  type="email"
                  component={OnboardingFormTextInput}
                  validate={validateEmail}
                />
              </GridElem>
            </Grid>

            {smallScreen && (
              <div>
                <OnboardingFormDescription
                  className={styles.text}
                  text={
                    <span>
                      By clicking on this button, you agree to our{" "}
                      <a
                        className={styles.link}
                        href={`https://neurahealth.co/terms-and-conditions${window.location.search}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        className={styles.link}
                        href={`https://neurahealth.co/privacy-policy${window.location.search}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      . Questions?{" "}
                      <a
                        className={styles.link}
                        href={
                          partner
                            ? "https://www.neurahealth.co/for-partners/questions"
                            : "https://www.neurahealth.co/frequently-asked-questions"
                        }
                      >
                        Click here
                      </a>
                    </span>
                  }
                />
              </div>
            )}

            <div
              className={cn("d-flex", {
                "justify-content-center": smallScreen,
              })}
            >
              <OnboardingFormSubmit
                className={styles.submitButton}
                title="Let’s keep going"
                disabled={!formikHelpers.isValid}
                isSubmitting={formikHelpers.isSubmitting}
              />
            </div>

            {!smallScreen && (
              <div className={styles.bottomText}>
                <OnboardingFormDescription
                  className={styles.text}
                  text={
                    <span>
                      By clicking on this button, you agree to our{" "}
                      <a
                        className={styles.link}
                        href={`https://neurahealth.co/terms-and-conditions${window.location.search}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        className={styles.link}
                        href={`https://neurahealth.co/privacy-policy${window.location.search}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      . Questions?{" "}
                      <a
                        className={styles.link}
                        href={
                          partner
                            ? "https://www.neurahealth.co/for-partners/questions"
                            : "https://www.neurahealth.co/frequently-asked-questions"
                        }
                      >
                        Click here
                      </a>
                    </span>
                  }
                />
              </div>
            )}

            <NumberOfStep step={step} />
          </Form>
        );
      }}
    </Formik>
  );
};
export default OnboardingFormSectionRegister;
