import React from "react";
import styles from "./DownloadComponent.module.scss";
import { ReactComponent as Play } from "../../assets/images/svg/play.svg";
import { ReactComponent as Apple } from "../../assets/images/svg/apple.svg";
import QRCode from "../../assets/images/qr-code.png";
import amplitude from "amplitude-js";

const downloadLinkIcons = [
  { icon: <Apple />, platform: "ios" },
  { icon: <Play />, platform: "android" },
];

export default function DownloadComponent(props: any) {
  const downloadEvent = (event: string, item: any) => {
    // @ts-ignore
    window?.dataLayer.push({
      event: event,
    });
    amplitude.getInstance().logEvent(event);

    window.open(
      item.platform === "ios"
        ? "https://apps.apple.com/us/app/id1562391775"
        : "https://play.google.com/store/apps/details?id=com.gezuntpatient",
      "_blank"
    );
  };

  return (
    <div className={styles.download}>
      <div className={styles.downloadTitle}>
        Please download the Neura app for the full experience - you can book visits, message us,
        request refills and more!
      </div>
      <img className={styles.qrcode} src={QRCode} alt="qrcode" />
      <div className={styles.downloadIcons}>
        {downloadLinkIcons.map((item: any, index: number) => (
          <button
            key={`${new Date()}${index}`}
            onClick={() => downloadEvent(`onboarding_v2_download_${item.platform}_click`, item)}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
}
