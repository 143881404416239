import React from "react";
import styles from "./Container.module.scss";
import cn from "classnames";

interface IContainer {
  children: any;
  className?: string;
  fluid?: boolean;
}

const Container = (props: IContainer) => {
  const { children, className, fluid } = props;

  return (
    <div className={cn(styles.element, className, fluid && styles["isFluid"])}>{children}</div>
  );
};

export default Container;
