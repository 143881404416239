import React, { useEffect, useRef, useState } from "react";
import Navigation from "../../components/Navigation/Navigation";
import firebase from "firebase/compat/app";
import styles from "./DoctorInfo.module.scss";
import Loader from "../../components/Loader/Loader";
import { useParams } from "react-router";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { ReactComponent as Arrow } from "../../assets/images/svg/arrow-left.svg";
import { Link } from "react-router-dom";

export default function DoctorInfo(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<any>(null);
  const [careTeamMember, setCareTeamMember] = useState<any>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const ref = useRef<any>(null);

  const { id } = useParams<any>();

  const { uid } = props;

  let firestore = firebase.firestore;

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle("no-chat", true);
  }, [isResponsiveView]);

  useEffect((): any => {
    let subscription: Promise<void>;
    if (uid) {
      setLoading(true);

      subscription = firestore()
        .collection("patients")
        .doc(uid)
        .get()
        .then((documentSnapshot) => {
          setPatientData(documentSnapshot.data());
        })
        .then(() => setLoading(false));
    }

    return () => subscription;
  }, [firestore, uid]);

  useEffect(() => {
    setLoading(true);
    firestore()
      .collection("care_team")
      .doc(id)
      .get()
      .then((documentSnapshot) => {
        const careTeamDoc: any = documentSnapshot.data();
        firebase
          .storage()
          .refFromURL("gs://gezunt-c3bfe.appspot.com/doctors/" + careTeamDoc.imageFileName)
          .getDownloadURL()
          .then((teamMemberImageURL) => {
            setCareTeamMember({
              ...careTeamDoc,
              ...{ careTeamMemberImageURL: teamMemberImageURL },
            });
          });
      })
      .then(() => setLoading(false));
  }, [firestore, id]);

  if (loading && !careTeamMember) return <Loader />;

  return (
    <div className={styles.doctor}>
      <div className={styles.container}>
        {!isResponsiveView && <Navigation patientData={patientData} />}
        {isResponsiveView && (
          <div className={styles.mobile}>
            <Link to={"/home"}>
              <Arrow />
            </Link>
            {scrollPosition > height + 40 && (
              <div className={styles.mobileImage}>
                <img
                  src={careTeamMember?.careTeamMemberImageURL}
                  alt={careTeamMember?.careTeamMemberImageURL}
                />
                <div className={styles.mobileBlock}>
                  <div className={styles.mobileName}>{careTeamMember?.name}</div>
                </div>
              </div>
            )}
          </div>
        )}
        <div ref={ref} className={styles.header}>
          <div className={styles.headerImage}>
            <img
              src={careTeamMember?.careTeamMemberImageURL}
              alt={careTeamMember?.careTeamMemberImageURL}
            />
            <div className={styles.headerBlock}>
              <div className={styles.headerName}>{careTeamMember?.name}</div>
              <div className={styles.headerStack}>{careTeamMember?.title}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.about}>
          <div className={styles.title}>About</div>
          <div className={styles.text}>{careTeamMember?.biography}</div>
          <div className={styles.title}>Work Experience</div>
          {careTeamMember?.workExperience &&
            careTeamMember?.workExperience.map((wExp: any) => (
              <div key={wExp} className={styles.text}>
                &#9679; &#09;{wExp}
              </div>
            ))}
          <div className={styles.title}>Education</div>
          {careTeamMember?.education &&
            careTeamMember.education.map((ed: any) => (
              <div key={ed} className={styles.text}>
                &#9679; &#09;{ed}
              </div>
            ))}
          {(careTeamMember?.healthgradesRating ||
            careTeamMember?.vitalsRating ||
            careTeamMember?.zocdocRating) > 0 && <div className={styles.title}>Reviews</div>}
          {careTeamMember?.healthgradesRating > 0 && (
            <div className={styles.text}>
              Healthgrades: {careTeamMember.healthgradesRating}/5{", "}
              {`${careTeamMember.healthgradesReviews} reviews`}
            </div>
          )}
          {careTeamMember?.vitalsRating > 0 && (
            <div className={styles.text}>
              Vitals: {careTeamMember.vitalsRating}/5{", "}
              {`${careTeamMember.vitalsReviews} reviews`}
            </div>
          )}
          {careTeamMember?.zocdocRating > 0 && (
            <div className={styles.text}>
              Zocdoc: {careTeamMember.zocdocRating}/5{", "}
              {`${careTeamMember.zocdocReviews} reviews`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
