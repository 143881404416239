import OnboardingFormSectionAddress from "../OnboardingFormSectionAddress/OnboardingFormSectionAddress";
import OnboardingFormSectionMedicalPersonal from "../OnboardingFormSectionMedicalPersonal/OnboardingFormSectionMedicalPersonal";
import OnboardingFormSectionPersonalData from "../OnboardingFormSectionPersonalData/OnboardingFormSectionPersonalData";

import { IOnboardingForm } from "../OnboardingForm";
import { ICoupon, IFormData } from "../OnboardingFormSection.interface";
import StripeHoc from "../OnboardingFormSectionPayment/StripeHoc";
import OnboardingFormSectionPayment from "../OnboardingFormSectionPayment/OnboardingFormSectionPayment";
import { useHistory, useLocation } from "react-router";
import OnboardingFormSectionRegister from "../OnboardingFormSectionRegister/OnboardingFormSectionRegister";
import { OnboardingFormCreatePassword } from "../OnboardingFormCreatePassword/OnboardingFormCreatePassword";
import OnboardingFormSelectionCondition from "../OnboardingFormSelectionCondition/OnboardingFormSelectionCondition";
import { useEffect } from "react";
import { useDefaultPasswordUpdate } from "../../../hooks/useDefaultPasswordUpdate";
import { useOnboardingStepCountContext } from "../../../context/onboardingStepCount";

interface IProps extends IOnboardingForm {
  formData?: IFormData;
  setFormData: (formData: any) => void;
  setFirstStep: () => void;
  coupon: ICoupon | null;
  rewardfulRef?: string | null;
  localGenPw?: string | null;
  setLocalGenPw: (pwd: string) => void;
}

export const B2cFlow = ({
  patientData,
  userEmail,
  formData,
  setFormData,
  step,
  setStep,
  setFirstStep,
  coupon,
  rewardfulRef,
  setLoading,
  loginMessage,
  setLoginMessage,
  uid,
  localGenPw,
  setLocalGenPw,
}: IProps) => {
  const { defaultPasswordUpdated, fetchDefaultPasswordUpdate } = useDefaultPasswordUpdate();
  const { setStepCount } = useOnboardingStepCountContext();
  const history = useHistory();
  const { state } = useLocation<any>();

  const ComponentsProps = {
    uid,
    patientData,
    userEmail,
    formData,
    setFormData,
    step,
    setFirstStep,
    loginMessage,
    setLoginMessage,
    localGenPw,
  };

  useEffect(() => {
    if (!uid) return;
    fetchDefaultPasswordUpdate(uid);
  }, [uid, step]);

  useEffect(() => {
    if (defaultPasswordUpdated) setStepCount(5);
    else setStepCount(6);
  }, [defaultPasswordUpdated]);

  switch (step) {
    default:
      return (
        <OnboardingFormSectionRegister
          setStep={setStep}
          setNextStep={() => setStep(1)}
          loginMessage={loginMessage}
          formData={formData}
          setFormData={setFormData}
          step={step}
          setLocalGenPw={setLocalGenPw}
        />
      );
    case 1:
      return (
        <OnboardingFormSectionPersonalData setNextStep={() => setStep(2)} {...ComponentsProps} />
      );
    case 2:
      return (
        <OnboardingFormSelectionCondition setNextStep={() => setStep(3)} {...ComponentsProps} />
      );
    case 3:
      return <OnboardingFormSectionAddress setNextStep={() => setStep(4)} {...ComponentsProps} />;
    case 4: {
      if (!defaultPasswordUpdated)
        return <OnboardingFormCreatePassword setNextStep={() => setStep(5)} {...ComponentsProps} />;

      return (
        <OnboardingFormSectionMedicalPersonal
          setLoading={setLoading}
          setNextStep={() => setStep(6)}
          coupon={coupon}
          {...ComponentsProps}
        />
      );
    }
    case 5: {
      return (
        <OnboardingFormSectionMedicalPersonal
          setLoading={setLoading}
          setNextStep={() => setStep(6)}
          coupon={coupon}
          {...ComponentsProps}
        />
      );
    }
    case 6:
      return (
        <StripeHoc
          setNextStep={() => setStep(7)}
          rewardfulRef={rewardfulRef}
          coupon={coupon}
          Component={OnboardingFormSectionPayment}
          {...ComponentsProps}
        />
      );
    case 7:
    case 8:
      history.push(state?.redirect || "/home");
      return <></>;
  }
};
