import Button from "../../Button/Button";
import styles from "./UpdatePaymentModal.module.scss";

type UpdatePaymentModalProps = {
  url: string;
  handleClose: () => void;
};

export const UpdatePaymentModal = ({ url, handleClose }: UpdatePaymentModalProps) => {
  const handleUpdatePayment = () => {
    handleClose();
    window.open(url, "_blank");
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Update Payment</h2>
      <p className={styles.content}>
        We require a payment method on file to confirm your visit. We will only charge your card for
        incidentals, such as copays or late cancellations.
      </p>
      <p className={styles.content}>
        You can update your payment information using the link below.
      </p>
      <Button shape="squared" onClick={handleUpdatePayment} className={styles.submit}>
        Payment Settings
      </Button>
      <Button variant="secondary" shape="squared" onClick={handleClose} className={styles.goBack}>
        Go Back
      </Button>
    </div>
  );
};
