import { SetStateAction, useState } from "react";

export const useInput = (initialValue: any) => {
  const [value, setValue] = useState<any>(initialValue);

  const handleChange = (event: { target: { value: SetStateAction<string> } }) => {
    setValue(event.target.value);
  };

  return {
    value,
    onChange: handleChange,
    setValue,
  };
};
