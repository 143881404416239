export type bookingProviderType = {
  acuityAppointmentLink: string;
};

export type bookingPatientType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isOnMaintenanceMode?: boolean;
};

export const getBookingURI = (
  currentProvider: bookingProviderType,
  patientData: bookingPatientType
) => {
  let bookingURI = currentProvider.acuityAppointmentLink + "?";

  bookingURI += `firstName=${encodeURIComponent(patientData.firstName)}&`;
  bookingURI += `lastName=${encodeURIComponent(patientData.lastName)}&`;
  bookingURI += `email=${encodeURIComponent(patientData.email)}&`;
  bookingURI += `phone=${encodeURIComponent(patientData.phoneNumber)}`;

  return bookingURI;
};
