import React from "react";
import cn from "classnames";
import amplitude from "amplitude-js";
import { NavLink } from "react-router-dom";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import AppStoreButton from "../AppStoreButton/AppStoreButton";
import { useMediaQuery } from "react-responsive";
import NewsletterForm from "../NewsletterForm/NewsletterForm";
import { BottomSiteNavMenu } from "../../helpers/BottomSiteNavMenu";
import GoogleMarketButton from "../GoogleMarketButton/GoogleMarketButton";
import EmailUnreadIcon from "../../assets/icons/email-action-unread.svg";
import HipaaIcon from "../../assets/icons/hipaa-icon.svg";
import LegitScriptIcon from "../../assets/images/LegitScript.png";
import RetroPhoneIcon from "../../assets/icons/retro-phone.svg";
import bottomNavMenu from "../../helpers/bottomNavMenu";
import footerIcons from "../../helpers/footerIcons";

import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import styles from "./Footer.module.scss";

const Footer = () => {
  const siteNavigationMenu = BottomSiteNavMenu();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["lg"]}px)`,
  });

  const renderIcons = footerIcons.map((el, idx) => {
    if (el.path.length > 0) {
      return (
        <a
          key={idx}
          className={styles.iconRowElement}
          href={el.path}
          rel="noreferrer"
          target="_blank"
        >
          <img src={el.img} alt="" />
        </a>
      );
    }
    return null;
  });

  const logEvent = (event: string) => {
    amplitude.getInstance().logEvent(event);
    // @ts-ignore
    window?.dataLayer.push({
      event: event,
    });
  };

  const renderBottomNeuraMenu = siteNavigationMenu.map((elem, key) => (
    <li className={styles.bottomNavigationLinkContainer} key={key}>
      <NavLink
        to={{ pathname: elem.to, search: window.location.search }}
        onClick={() => {
          logEvent(elem.event);
        }}
      >
        {elem.title}
      </NavLink>
    </li>
  ));

  const renderBottomNavMenu = bottomNavMenu.map((el) => (
    <li className={styles.additionalNavigationLinkContainer} key={el.title}>
      <a href={el.to} rel="noreferrer" target="_blank">
        {el.title}
      </a>
    </li>
  ));

  const renderNavMenu = siteNavigationMenu.map((elem, key) => (
    <li className={cn(styles.item, "d-inline-flex")} key={key}>
      <NavLink
        to={{ pathname: elem.to, search: window.location.search }}
        className={styles.navigationLink}
        onClick={() => {
          logEvent(elem.event);
        }}
      >
        {elem.title}
      </NavLink>
    </li>
  ));

  return (
    <>
      <div className={styles.upperSectionContainer}>
        <Container>
          <div className={styles.upperSection}>
            <div className={styles.navigationAndDownloadContainer}>
              <div className={styles.downloadButtonContainer}>
                <AppStoreButton className={styles.downloadButton} />

                <GoogleMarketButton className={styles.downloadButton} />
              </div>

              {!isResponsiveView && <ul className={cn(styles.navigationMenu)}>{renderNavMenu}</ul>}
            </div>

            <div className={styles.keepInTouchColumn}>
              <Heading size="h3" title="Keep in touch" />

              <span className={styles.keepInTouchText}>Sign up to get updates from us.</span>

              <div className={styles.inputContainer}>
                <NewsletterForm />
              </div>

              <div className={styles.iconsContainer}>
                <img className={styles.icon} src={HipaaIcon} alt="" />

                <img className={styles.icon} src={LegitScriptIcon} alt="" />
              </div>

              {!isResponsiveView && (
                <span className={styles.firstColumnText}>
                  Content on this site does not constitute actual medical advice, diagnosis or
                  treatment. Sign up for the Neura app to consult with an actual doctor.
                </span>
              )}
            </div>

            <div className={styles.contactsColumn}>
              <div className={styles.phoneContact}>
                <img className={styles.retroPhone} src={RetroPhoneIcon} alt="" />

                <div>
                  <Heading
                    className={styles.phoneNumberText}
                    size="h3"
                    title={<a href="tel:+1 848-272-1134">+1 848-272-1134</a>}
                  />

                  <Heading className={styles.days} size="h4" title="Mon-Fri: 8:00am-9:00pm EST." />

                  <Heading
                    className={styles.message}
                    size="h4"
                    title="Message and data rates may apply."
                  />
                </div>
              </div>

              <div className={styles.emailContact}>
                <img className={styles.emailImage} src={EmailUnreadIcon} alt="" />

                <Heading
                  className={styles.emailText}
                  size="h3"
                  title={<a href="mailto:support@neurahealth.co">support@neurahealth.co</a>}
                />
              </div>

              <div className={styles.followUs}>
                <Heading size="h3" title="Follow Us" className={styles.followUsHeading} />

                <div className={styles.iconsRow}>{renderIcons}</div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className={styles.bottomSectionContainer}>
        <Container className={styles.bottomSection}>
          <div className={styles.navigationsContainer}>
            {isResponsiveView && <ul>{renderBottomNeuraMenu}</ul>}

            <ul className={styles.additionalNavigation}>{renderBottomNavMenu}</ul>
          </div>

          {isResponsiveView && (
            <span className={styles.firstColumnText}>
              Content on this site does not constitute actual medical advice, diagnosis or
              treatment. Sign up for the Neura app to consult with an actual doctor.
            </span>
          )}

          <span className={styles.bottomSectionText}>&#xa9;2021 All rights reserved</span>
        </Container>
      </div>
    </>
  );
};

export default Footer;
