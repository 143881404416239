import { useHistory, useLocation } from "react-router";
import { OnboardingLayout } from "../../components/OnboardingLayout/OnboardingLayout";
import qs from "query-string";
import { useEffect } from "react";
import { FeedbackMessageCard } from "../../components/FeedbackMessageCard/FeedbackMessageCard";

type queryType = { apiKey: string; lang: string; mode: string; oobCode: string };

export const ActionGooglePage = () => {
  const history = useHistory();
  const params = useLocation();
  const query = qs.parse(params.search) as queryType;

  useEffect(() => {
    if (query.mode === "resetPassword") history.push(`/set-new-password${params.search}`);
    if (query.mode === "verifyEmail") history.push(`/verify-email${params.search}`);
  }, [query.mode]);

  return (
    <OnboardingLayout visibleLogo>
      <FeedbackMessageCard
        title="404"
        subtitle="Page Not Found"
        buttonLabel="Return to homepage"
        redirect={() => window.location.replace("https://www.neurahealth.co/")}
      />
    </OnboardingLayout>
  );
};
