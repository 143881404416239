import FirebaseAuth from "../FirebaseAuth/FirebaseAuth";
import FirebaseFirestore from "../FirebaseFirestore/FirebaseFirestore";
import FirebaseFunctions from "../FirebaseFunctions/FirebaseFunctions";

interface IFirebaseApi {
  firebaseFirestore(): any;
  auth(): FirebaseAuth;
  firebaseFunctions(): FirebaseFunctions;
}

class FirebaseApi implements IFirebaseApi {
  auth() {
    return new FirebaseAuth();
  }

  firebaseFirestore() {
    return new FirebaseFirestore();
  }

  firebaseFunctions() {
    return new FirebaseFunctions();
  }
}

export default FirebaseApi;
